import React, { useEffect, useState } from 'react';
import { Form, Grid } from "tabler-react";
import Crud_Catalogos from '../herramientas/Crud_Catalogos';
import Select from "react-select";
import { TablePageable } from "../Helpers";

//const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
const user = JSON.parse(sessionStorage.getItem("usuario"));
;
const AlertasPrioritarias = () => {
  //console.log(user);

  const [idPlataforma, setIdPlataforma] = useState({ value: 0, label: "Selecciona una plataforma" });
  const [plataformas, setPlataformas ]= useState([]); 
  const [tiempoAtencionAlertasPrioritarias, setTiempoAtencionAlertasPrioritarias] = useState([]);

  useEffect(() =>{
    getfuntion("get", "", "", "plataformas/usuario/"+user.id, "plataformas");      
  },[]);

  useEffect(() => { 
    if(idPlataforma.value){
      getfuntion("get", "", "", "navierasAlertas/listaAlertasPrioritariasUsuariosPorPlataforma/" + idPlataforma.value + "/" + user.id, "tiempoAtencionAlertasPrioritarias"); 
    }               
  }, [idPlataforma]);


  const changePlataformas = (event) => {
    //console.log(event)
    setIdPlataforma(event);   
  };

  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {    
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":             
            if(stateVar === "plataformas" && returnVal){
              let plataformasTmp= [];
              for(let p of returnVal)
                plataformasTmp.push({value: p.id, label: p.nombre, data: p});
              setPlataformas(plataformasTmp);
              if(plataformasTmp.length === 1 )
                changePlataformas(plataformasTmp[0]);
            }  
            if(stateVar === "tiempoAtencionAlertasPrioritarias" && returnVal) {
              let tiempoAtencionAlertasPrioritariasTemp = [];
              returnVal.map((i, index) => {
                tiempoAtencionAlertasPrioritariasTemp.push(i)
              });
              setTiempoAtencionAlertasPrioritarias(tiempoAtencionAlertasPrioritariasTemp);
            }          
            break;            
          default:
            break;
        }
    }).catch(err => { console.log(err); });
}

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
            <h4 className="modal-title"> Tiempo de Atención de Alertas Prioritarias </h4>
        </div>
        <div className="modal-body" style={{ width: "98%"}}>
            <div style={{ height: "100%"}}>
              {!idPlataforma.value ? (
                <Grid.Row >
                  <h7 className="alert alert-primary" style={{ color: 'blue' }}>Selecciona una plataforma para ver el tiempo de atención de alertas prioritarias</h7>
                </Grid.Row>
              ): null}            
              <Grid.Row>
                  <Grid.Col md={12} xl={12}>
                      <Grid.Row>
                      <Grid.Col md={4} lg={4}>
                          <Form.Group label="Plataforma">
                            <Select
                              value = {idPlataforma}
                              onChange = {changePlataformas}
                              options = {plataformas}
                              isDisabled = {plataformas.length === 1}
                            />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                  </Grid.Col>
              </Grid.Row>  
              {idPlataforma.value ? (
              <Grid.Row>
                <TablePageable
                  titulo={""}
                  lista={ tiempoAtencionAlertasPrioritarias }
                  columnas={
                    [
                      {columna: "id_alerta", label: "Id Alerta"},
                      {columna: "plataforma", label: "Plataforma"},
                      {columna: "id_movimiento", label: "Movimiento"},
                      {columna: "alerta_tipo", label: "Tipo de Alerta"},
                      {columna: "fecha_registro", label: "Fecha Registro Alerta"},
                      {columna: "atiende_fecha", label: "Fecha Atención Alerta"},
                      {columna: "tiempo_atencion", label: "Tiempo Atención"},
                      {columna: "atiende_usuario", label: "Id Usuario"},
                      {columna: "usuario_atendio", label: "Nombre Usuario"},
                    ]
                  }
                  id= "id_alerta"            
                  csvname= {"Tiempo Atencion Aertas Prioritarias"}            
                  paginacion={true}
                  elementosPorPagina={50}
                />
              </Grid.Row>
              ): null}                                    
            </div>
        </div>                          
      </div>
      
    </>
  )
}
;
export default AlertasPrioritarias;
