import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { validarToken } from './herramientas/JWT';
import {
  AcumuladoresPage,
  AlertasCategoriasPage,
  AlertasTiposPage,
  AdminstracionPage,
  AdminPage,
  DashboardNavieraPage,
} from './pages';
import {
  BloquesPage,
  ServiceFailurePage,
  BitacoraMonitoreoPage,
} from './pages';
import {
  CategoriaPage,
  ClientesPage,
  ClientesContactosPage,
  OperacionesClientesPage,
  CorredoresPage,
  LocalidadesPage,
  WhatsAppGruposPages,
  PatiosVaciosPage,
} from './pages';
import { DestinosPage } from './pages';

import {
  Error400,
  Error401,
  Error403,
  Error404,
  Error500,
  Error503,
} from './pages';
import { EtiquetasPage, EtaConfiguracionesPage } from './pages';
import { FuncionalidadesPage } from './pages';

import {
  GpsTipoColocacionPage,
  GPSPage,
  GpsFijosPage,
  GPSHardwarePage,
  GPSEstatusPage,
} from './pages';

import HomePage from './pages/dashboards/HomePage.react';
import {
  DashboardTerrestrePage,
  DashBoardGpsYearPage,
  ChatBotPage,
  DashBoardGpsEstatusPage,
  GpsRecuperarDash,
} from './pages';

import { GpsEnvioPage } from './pages';

import {
  IncidenciasTipoPage,
  InformesPage,
  CoberturasPage,
  InformesFuncionalidadesPage,
  IncidenciasPage,
} from './pages';

import LogOut from './Auth/LogOut';
import { LoginPage } from './pages';

import {
  MovimientosTipoPage,
  MonitoreoPage,
  MonitoreoImpoPage,
  MonitoreoExpoPage,
  MovimientoGpsPage,
  UnidadesSinRutaPage,
  AlertasUnidadesEnRutaPage,
  SkyColdPage,
  MovimientosNavierosClientePage,
  MonitoreoOTD,
  noticiasPage
} from './pages';

import { CatalogosLogPage } from './pages';

import { NavierasPage, ClientesNavierosPage } from './pages';
import {
  OperacionesTipoPage,
  OperacionesEstatusPage,
  OperadoresPage,
} from './pages';

import {
  PersonasPage,
  PerfilesPage,
  PlataformasPage,
  PlataformasTipoPage,
  PrivilegiosPage,
  PatiosPage,
} from './pages';

import { RutasEstatusPage, RecuperarGpsPage, ReglasPage } from './pages';

import { SalaPage, TransportistasPage } from './pages';

import {
  UsuariosPage,
  UnidadesPage,
  UnidadesNavierasPage,
  UnidadesMarcasPage,
  UsuarioConsolaPage,
  UnidadTipoPage,
  UnidadElementoPage,
  UsuarioOperacionClientePage,
  UnidadEstatusOperacionPage,
  UnidadTipoColocacionPage,
  DetalleRemolques,
  //ReporteRemolques
} from './pages';

/**
 * Usuario
 */
import { ProfilePage } from './pages';

//import "tabler-react/dist/Tabler.css";
import OtdPage from './pages/catalogosPages/OtdPage.react';
//require ("./Temas/TablerDark.css");

/**
 * Reportes
 */
import {
  ReportesPage,
  ReportesGeneralesPage,
  ReportesLLAmadasPage,
  ReportesAlertasCctvPage,
} from './pages';

import { DashBoardUsersPage } from './pages';

//Tablero de Captura en tiempo
import { EstatusGerenciador } from './pages';
import { DRP } from './pages';

import {} from './pages';
import { MapaDeRiesgo } from './pages';
import { TerminadoMasivo } from './pages';

import { TableroCapturaEnTiempo } from './pages';
import { CorreosProgramados } from './pages';
import LoginTelefonoPage from './pages/loginTelefonoPages/LoginTelefonoPage';
import AvisoPrivacidadPage from './pages/AvisoPrivacidadPage/AvisoPrivacidadPage';
import GpsAlmacen from './pages/gpsAlmacen/GpsAlmacen';
import Telefono from './pages/TelefonoPage/Telefono/Telefono';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MovimientosPatioPage } from './pages/MovimientosPatioPage/MovimientosPatioPage';
//Qlik
import MapaGpsQlikPage from './pages/MapaGpsQlikPage/MapaGpsQlikPage';
import DashboardAlertasTerrestreQlikPage from './pages/DashboardAlertasTerrestreQlikPage/DashboardAlertasTerrestreQlikPage';
import DashboardAlertasNavieraQlikPage from './pages/DashboardAlertasNavieraQlikPage/DashboardAlertasNavieraQlikPage';
import DashboardLlamadasPorEstatusQlikPage from './pages/DashboardLlamadasPorEstatusQlikPage/DashboardLlamadasPorEstatusQlikPage';
import DashboardLlamadasPorTipoQlikPage from './pages/DashboardLlamadasPorTipoQlikPage/DashboardLlamadasPorTipoQlikPage';

require('./Temas/TablerDefault.css');

var user = JSON.parse(sessionStorage.getItem('usuario'));
var currentTema = user
  ? JSON.parse(localStorage.getItem('skyonetema' + user.id))
  : null;
var hoja = document.createElement('style');
if (currentTema) {
  if (currentTema.value) {
    hoja.innerHTML = currentTema.tema;
    document.body.appendChild(hoja);
  }
}
const paginaspublicas = ['LOGOUT', 'LOGIN', 'AVISO_PRIVACIDAD_MOVIL'];
let ban = false;
for (let p of paginaspublicas) {
  if (window.location.href.toUpperCase().includes(p)) {
    ban = true;
  }
}
if (!ban) {
  validarToken(sessionStorage.getItem('tok'));
}

// Create a client
const queryClient = new QueryClient();

function App(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <React.StrictMode>
          <Router>
            <Switch>
              <Route exact path="/gps-almacen" component={GpsAlmacen} />
              <Route exact path="/dashboard_GPS" component={HomePage} />
              <Route
                exact
                path="/tableroCapturaEnTiempo"
                component={TableroCapturaEnTiempo}
              />
              <Route exact path="/mapaDeRiesgo" component={MapaDeRiesgo} />
              {/* Qlik */}
              <Route exact path="/qlik/mapaGps" component={MapaGpsQlikPage} />
              <Route exact path="/qlik/dashboardAlertasTerrestre" component={DashboardAlertasTerrestreQlikPage} />
              <Route exact path="/qlik/dashboardAlertasNaviera" component={DashboardAlertasNavieraQlikPage} />
              <Route exact path="/qlik/dashboardLlamadasPorEstatus" component={DashboardLlamadasPorEstatusQlikPage} />
              <Route exact path="/qlik/dashboardLlamadasPorTipo" component={DashboardLlamadasPorTipoQlikPage} />
              <Route
                exact
                path="/terminadoMasivo"
                component={TerminadoMasivo}
              />

              <Route exact path="/chatBot" component={ChatBotPage} />

              <Route
                exact
                path="/dashboardTerrestre"
                component={DashboardTerrestrePage}
              />
              <Route
                exact
                path="/dashboardNaviera"
                component={DashboardNavieraPage}
              />
              <Route
                exact
                path="/correosProgramados"
                component={CorreosProgramados}
              />

              <Route exact path="/400" component={Error400} />
              <Route exact path="/401" component={Error401} />
              <Route exact path="/403" component={Error403} />
              <Route exact path="/404" component={Error404} />
              <Route exact path="/500" component={Error500} />
              <Route exact path="/503" component={Error503} />

              <Route exact path="/Admin" component={AdminPage} />
              <Route
                exact
                path="/adminstracion"
                component={AdminstracionPage}
              />
              <Route exact path="/acumuladores" component={AcumuladoresPage} />
              <Route
                exact
                path="/alertasCategorias"
                component={AlertasCategoriasPage}
              />
              <Route exact path="/alertasTipos" component={AlertasTiposPage} />

              <Route exact path="/bloques" component={BloquesPage} />
              <Route
                exact
                path="/serviceFailures"
                component={ServiceFailurePage}
              />
              <Route
                exact
                path="/bitacoramonitoreo"
                component={BitacoraMonitoreoPage}
              />
              <Route exact path="/categoria" component={CategoriaPage} />
              <Route exact path="/clientes" component={ClientesPage} />
              <Route exact path="/coberturas" component={CoberturasPage} />
              <Route
                exact
                path="/clientesContactos"
                component={ClientesContactosPage}
              />
              <Route
                exact
                path="/operacionesClientes"
                component={OperacionesClientesPage}
              />

              <Route exact path="/corredores" component={CorredoresPage} />
              <Route exact path="/localidades" component={LocalidadesPage} />

              <Route exact path="/destinos" component={DestinosPage} />

              <Route exact path="/etiquetas" component={EtiquetasPage} />
              <Route
                exact
                path="/etaConfiguraciones"
                component={EtaConfiguracionesPage}
              />

              <Route
                exact
                path="/funcionalidades"
                component={FuncionalidadesPage}
              />

              <Route exact path="/gps" component={GPSPage} />
              <Route exact path="/gpsFijos" component={GpsFijosPage} />
              <Route exact path="/gpsestatus" component={GPSEstatusPage} />
              <Route
                exact
                path="/gpstipocolocacion"
                component={GpsTipoColocacionPage}
              />
              <Route exact path="/gpsHardware" component={GPSHardwarePage} />

              <Route exact path="/informes" component={InformesPage} />
              <Route
                exact
                path="/informesFuncionalidades"
                component={InformesFuncionalidadesPage}
              />

              <Route
                exact
                path="/incidenciatipo"
                component={IncidenciasTipoPage}
              />
              <Route exact path="/incidencias" component={IncidenciasPage} />

              <Route exact path="/logout" component={LogOut} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/telefono/:usuario" component={Telefono} />
              <Route
                exact
                path="/login_telefono"
                component={LoginTelefonoPage}
              />
              <Route
                exact
                path="/aviso_privacidad_movil"
                component={AvisoPrivacidadPage}
              />

              <Route
                exact
                path="/movimientostipo"
                component={MovimientosTipoPage}
              />
              <Route exact path="/navieras" component={NavierasPage} />
              <Route
                exact
                path="/clientes_navieros"
                component={ClientesNavierosPage}
              />
              <Route exact path="/otd" component={OtdPage} />

              <Route exact path="/perfilusuario" component={ProfilePage} />
              <Route exact path="/personas" component={PersonasPage} />
              <Route exact path="/plataformas" component={PlataformasPage} />
              <Route
                exact
                path="/plataformastipo"
                component={PlataformasTipoPage}
              />
              <Route exact path="/privilegios" component={PrivilegiosPage} />
              <Route exact path="/perfiles" component={PerfilesPage} />

              <Route exact path="/reglas" component={ReglasPage} />
              <Route exact path="/rutasestatus" component={RutasEstatusPage} />

              <Route
                exact
                path="/transportistas"
                component={TransportistasPage}
              />

              <Route exact path="/monitoreo" component={MonitoreoPage} />
              <Route
                exact
                path="/monitoreoimpo"
                component={MonitoreoImpoPage}
              />
              <Route
                exact
                path="/monitoreoexpo"
                component={MonitoreoExpoPage}
              />
              <Route
                exact
                path="/movimientos_nav_clien"
                component={MovimientosNavierosClientePage}
              />
              <Route exact path="/salasMonitoreo" component={SalaPage} />

              <Route exact path="/patios" component={PatiosPage} />

              <Route
                exact
                path="/operacionesEstatus"
                component={OperacionesEstatusPage}
              />
              <Route
                exact
                path="/operacionestipo"
                component={OperacionesTipoPage}
              />
              <Route exact path="/operadores" component={OperadoresPage} />
              <Route exact path="/recuperarGps" component={RecuperarGpsPage} />

              <Route
                exact
                path="/usuarioConsola"
                component={UsuarioConsolaPage}
              />
              <Route
                exact
                path="/usuarioTerrestre"
                component={UsuarioOperacionClientePage}
              />
              <Route exact path="/usuarios" component={UsuariosPage} />
              <Route
                exact
                path="/movimientoGps"
                component={MovimientoGpsPage}
              />
              <Route
                exact
                path="/movimientosPatio"
                component={MovimientosPatioPage}
              />
              <Route exact path="/unidades" component={UnidadesPage} />
              <Route
                exact
                path="/unidades_navieras"
                component={UnidadesNavierasPage}
              />
              <Route
                exact
                path="/unidadesmarcas"
                component={UnidadesMarcasPage}
              />
              <Route exact path="/unidadTipo" component={UnidadTipoPage} />
              <Route
                exact
                path="/unidadElementos"
                component={UnidadElementoPage}
              />
              <Route
                exact
                path="/unidadEstatusOperacion"
                component={UnidadEstatusOperacionPage}
              />
              <Route
                exact
                path="/unidadTipoColocacion"
                component={UnidadTipoColocacionPage}
              />
              <Route
                exact
                path="/whatsapp_grupos"
                component={WhatsAppGruposPages}
              />
              <Route
                exact
                path="/unidadessinruta"
                component={UnidadesSinRutaPage}
              />
              <Route
                exact
                path="/alertasunidadesenruta"
                component={AlertasUnidadesEnRutaPage}
              />
              <Route exact path="/skycold" component={SkyColdPage} />
              <Route exact path="/catalogos_log" component={CatalogosLogPage} />
              <Route exact path="/reportes" component={ReportesPage} />
              <Route
                exact
                path="/reportesGenerales"
                component={ReportesGeneralesPage}
              />
              <Route
                exact
                path="/reporte_llamdas"
                component={ReportesLLAmadasPage}
              />
              <Route
                exact
                path="/reporte_alarmas_cctv"
                component={ReportesAlertasCctvPage}
              />
              <Route
                exact
                path="/dashboard_users"
                component={DashBoardUsersPage}
              />
              <Route exact path="/gerenciador" component={EstatusGerenciador} />
              <Route exact path="/drp" component={DRP} />
              <Route
                exact
                path="/dashboard_gps_year"
                component={DashBoardGpsYearPage}
              />
              <Route
                exact
                path="/dashboard_gps_estatus"
                component={DashBoardGpsEstatusPage}
              />
              <Route exact path="/gps_envio" component={GpsEnvioPage} />
              <Route
                exact
                path="/dashboard_gps_recuperar"
                component={GpsRecuperarDash}
              />

              <Route
                exact
                path="/update_remolques"
                component={DetalleRemolques}
              />
            {/* <Route 
                exact 
                path="/reporte_remolques"     
                component={ReporteRemolques}
            /> */}

            <Route exact path="/monitoreoOTD" component={MonitoreoOTD} />

            <Route exact path="/noticias" component={noticiasPage} />
            <Route exact path="/patiosVacios" component={PatiosVaciosPage} />

            <Route component={Error404} />
          </Switch>
        </Router>
      </React.StrictMode>
    </div>
    </QueryClientProvider>
  );
}

export default App;
