import {  Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import { timeToDateNumbers } from "../../herramientas/DateFormat";
import { NotificationManager } from "react-notifications";

export function Arroba(idPlataforma, movimiento, acumulador, etiqueta,imagen, nota){
    let formData = new FormData();
    if(imagen){        
        formData.append("imagen",imagen);
    }
    Crud_Catalogos("whatsapp_configuracion/plataforma/"+idPlataforma, "whatsapp_configuracion", "get", "").then((returnVal) => {
        if( returnVal.id ){
            //Crud_Catalogos("whatsapp_mensajes/naviero?acumulador=3&id_plt="+idPlataforma+"&id_mov="+movimiento, "movimientos", "post", "").then((returnVal) => {
            Crud_Catalogos("whatsapp/v2/enviar_mensajes?id_plt="+idPlataforma+"&id_mov="+movimiento+"&acumulador="+acumulador+"&etiqueta="+etiqueta, "movimientos", "post", "").then((returnVal) => {
                NotificationManager.info(
                    "Notificación de whatsApp enviada, mov: "+ movimiento, "Notificación"
                  );
            }).catch(err =>{ console.log("error: "+err);});
        }
    }).catch(err =>{ console.log("error: "+err);});
        
    return Crud_Catalogos("mail/arrobanaviera/"+movimiento+"/"+acumulador+"?nota="+nota, "movimientos", "post", "", imagen? formData: null).then((returnVal) => {
        //console.log("respuesta: ",returnVal);
        return returnVal;
    }).catch(err =>{
        console.log("error: "+err);
        return "Error desconocido ....";
    })
}

/**
 * Envia 2 mov en una solo notificacion
 * @param {*} idPlataforma 
 * @param {*} movimientos 
 * @param {*} acumulador 
 * @param {*} imagen 
 * @param {*} nota 
 * @returns 
 */
export function ArrobaFull(idPlataforma, movimientos, acumulador, etiqueta, imagen, nota){
    let formData = new FormData();
    if(imagen){        
        formData.append("imagen",imagen);
    }
    
    Crud_Catalogos("whatsapp_configuracion/plataforma/"+idPlataforma, "whatsapp_configuracion", "get", "").then((returnVal) => {
        if( returnVal.id ){
            for(let m of movimientos){
                Crud_Catalogos("whatsapp/v2/enviar_mensajes?id_plt="+idPlataforma+"&id_mov="+m+"&acumulador="+acumulador+"&etiqueta="+etiqueta, "movimientos", "post", "").then((returnVal) => {
                    NotificationManager.info(
                        "Notificación de whatsApp enviada, mov: "+ m, "Notificación"
                        );
                }).catch(err =>{ console.log("error: "+err);});
            }
        }
    }).catch(err =>{ console.log("error: "+err);});
    
        
    return Crud_Catalogos("mail/arrobanaviera_full/"+movimientos+"/"+acumulador+"?nota="+nota, "movimientos", "post", "", imagen? formData: null).then((returnVal) => {
        return returnVal;
    }).catch(err =>{
        console.log("error: "+err);
        return "Error desconocido ....";
    });
}

export function ArrobaTerrestre(idmovmiento, acumulador){
    return Crud_Catalogos("mail/arrobaterrestre/"+idmovmiento+"/"+acumulador, "movimientos", "get", "").then((returnVal) => {
        return returnVal;
        }).catch(err =>{
        if( err.response ){
            console.log(err.response);
            return err.response;
        }else{
            return "Error desconocido ....";
        }
    })
}
export async function ArrobaIncidencia(idPlataforma, movimiento, id, imagen, etiqueta){
    let formData = new FormData();
    if(imagen){
        let imagenTemp= new File([imagen], 1+"_"+idPlataforma+"_"+movimiento+"_"+timeToDateNumbers(new Date().getTime())+"."+imagen.name.split('.').pop(), {//original.name | acumulador_idPlataforma_movimiento
            type: imagen.type,
            lastModified: imagen.lastModified,
        });        
        await cargar_imagen_por_acumulador(imagenTemp);
        formData.append("imagen", imagen);
    }
    await Crud_Catalogos("whatsapp_configuracion/plataforma/"+idPlataforma, "whatsapp_configuracion", "get", "").then((returnVal) => {
        if( returnVal.id ){
            Crud_Catalogos(
              //"whatsapp_mensajes/naviero?acumulador=1&id_plt="+idPlataforma+"&id_mov="+movimiento,
              "whatsapp/v2/enviar_mensajes?id_plt="+idPlataforma+"&id_mov="+movimiento+"&acumulador="+1+"&etiqueta="+etiqueta,
              "movimientos",
              "post",
              "").then((returnVal) => {
                //console.log("respuesta whatsApp: ", returnVal);
            }).catch(err =>{ console.log("error: "+err);});
        }
    }).catch(err =>{ console.log("error: "+err);});
    
    return Crud_Catalogos("mail/arrobanavieraincidencia/"+id, "movimientos", "post", "", formData).then((returnVal) => {
        return returnVal;
        }).catch(err =>{
        if( err.response ){
            console.log(err.response);
            return err.response;
        }else{
            return "Error desconocido ....";
        }
    })
}
function cargar_imagen_por_acumulador(imagen){
    let formData = new FormData();
    if(imagen){        
        formData.append("file",imagen);
        return Crud_Catalogos("cargar_imagen_por_acumulador", "cargar_imagen_por_acumulador", "post", "", formData).then((returnVal) => {
            return returnVal;
            }).catch(err =>{ console.log("error: "+err);});
    }else{
        return false;
    }
}
export async function ArrobaIncidenciaTerrestre(id, imagen){
    let formData = new FormData();
    if(imagen){
        formData.append("imagen", imagen);
    }
    await Crud_Catalogos("mail/arrobaterrestreincidencia/"+id, "movimientos", "post", "", formData).then((returnVal) => {
        NotificationManager.info(
            returnVal, "Notificación"
        );
        return returnVal;
    }).catch(err =>{
        if( err.response ){
            console.log(err.response);
            //return err.response;
            NotificationManager.error(
                "Ha ocurrido un error al enviar correo: "+ err.response, "Notificación"
            );
            return err.response;
        }else{
            NotificationManager.error(
                "Error desconocido ....", "Notificación"
            );
            return "Error desconocido ....";
        }
    });
}
export async function WhatsAppIncidenciaTerrestre(idPlataforma, movimiento, imagen, etiqueta){
    if(imagen){
        let imagenTemp= new File([imagen], 1+"_"+idPlataforma+"_"+movimiento+"_"+timeToDateNumbers(new Date().getTime())+"."+imagen.name.split('.').pop(), {//original.name | acumulador_idPlataforma_movimiento
            type: imagen.type,
            lastModified: imagen.lastModified,
        });        
        await cargar_imagen_por_acumulador(imagenTemp);
    }
    await Crud_Catalogos("whatsapp_configuracion/plataforma/"+idPlataforma, "whatsapp_configuracion", "get", "").then((returnVal) => {
        if( returnVal.id ){
            Crud_Catalogos(
                //"whatsapp_mensajes/naviero?acumulador=1&id_plt="+idPlataforma+"&id_mov="+movimiento,
                "whatsapp/v2/enviar_mensajes?id_plt="+idPlataforma+"&id_mov="+movimiento+"&acumulador="+1+"&etiqueta="+etiqueta,
                "movimientos",
                "post",
                "").then((returnVal) => {
                NotificationManager.info(
                    (returnVal.message? returnVal.message:""), "Notificación"
                );
                return returnVal;
                //console.log("respuesta whatsApp: ", returnVal);
            }).catch(err =>{
                console.log("error: "+err);
                NotificationManager.error(
                    "Ha ocurrido un error al enviar WhatsApp", "Notificación"
                );
                return "Ha ocurrido un error al enviar WhatsApp";
            });
        }else{
            NotificationManager.warning(
                "No tienes whatsApp configurado enviar WhatsApp", "Notificación"
            );
            return "No tienes whatsApp configurado enviar WhatsApp";
        }
    }).catch(err =>{
        console.log("error: "+err);
        NotificationManager.error(
            "Ha ocurrido un error al obtener configuración WhatsApp", "Notificación"
        );
        return "Ha ocurrido un error al obtener configuración WhatsApp";
    });
}
export function ArrobaIncidenciaTerrestreTraxion(id, imagen){
    let formData = new FormData();
    if(imagen){        
        formData.append("imagen", imagen);
    }
    return Crud_Catalogos("mail/arrobaterrestreincidencia_traxion/"+id, "movimientos", "post", "", formData).then((returnVal) => {
          return returnVal;
        }).catch(err =>{
        if( err.response ){
            console.log(err.response);
            return err.response;
        }else{
            return "Error desconocido ....";
        }
    })
}

export default Arroba;
