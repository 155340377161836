// @flow

import React, { Component } from 'react';
import {
  Card,
  FormTextInput,
  Button,
  Icon,
  Grid,
  Alert,
  Dimmer,
} from 'tabler-react';
import defaultStrings from '../../data/LoginPage.strings';
import { Auth } from '../../Auth/Auth';
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import { NavLink, withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { tokenGetUserName } from '../../herramientas/JWT';
import { faL } from '@fortawesome/free-solid-svg-icons';
let loading = false;
class LoginPage extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      username: '',
      password: '',
      showmessage: false,
      message: 'No message',
      loginMessage: null,
      /*se crea Bandera para habilitar menu personalizado de pantalla de Monitoreo */
      banderaPersonalizada:false
    };
    this.props.history.push('/login');
  }
  componentWillMount = () => {
    console.log("cambios")
    console.log(this.state.banderaPersonalizada)
    //alert(window.location);
  };
  changePassword = (event) => {
    this.setState({ password: event.target.value });
  };
  changeUsername = (event) => {
    this.setState({ username: event.target.value });
  };
  getLogin = async () => {
    if (loading) {
      return false;
    } else {
      loading = true;
    }
    let navBarItems = [];
    let monitoreoSubItems = [];
    let modulosMonitoreoSubItems = [];
    let dashboardSubItems = [];
    let moduloArrastreSubItems = [];
    let mapasSubItems = [];
    let consultasSubItems = [];
    let reportesSubItems = [];
    let Dashboard = false;
    let Administracion = false;
    let monitoreo = false;
    let resp = [];
    let usuario;
    let perfil;
    this.setState({ showmessage: false });
    await Auth('login', this.state.username, this.state.password)
      .then((res) => {
        console.log("props")
    console.log(res)
        resp = res;
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loginMessage: null });
        this.setState({ username: '' });
        this.setState({ password: '' });
        this.setState({ message: '' + err });
        this.setState({ showmessage: true });
      });
    if (resp.token) {
      this.setState({ loginMessage: 'Cargando perfiles ..' });
      await Crud_Catalogos(
        'plataformasUsuariosPerfiles/username',
        'usuarios',
        'post',
        '',
        { username: this.state.username },
        '',
        'currentUser'
      )
        .then((returnVal) => {

          if(returnVal[0].plataforma.id == 1){
            this.setState({banderaPersonalizada:true});
          }
        
          usuario =
            returnVal[0] && returnVal[0].usuario ? returnVal[0].usuario : null;
          perfil =
            returnVal[0] && returnVal[0].perfil ? returnVal[0].perfil : null;
          this.setState({ loginMessage: 'Construyendo menu de opciones ...' });
          sessionStorage.setItem('usuario', JSON.stringify(usuario));

          sessionStorage.setItem('perfil', JSON.stringify(returnVal[0].perfil));
          sessionStorage.setItem(
            'plataforma',
            JSON.stringify(returnVal[0].plataforma)
          );
          sessionStorage.setItem('currentUser', JSON.stringify(returnVal));
          console.log(returnVal)
        })
        .catch((err) => {
          console.log(err);
          //window.location.href = "/login";
        });
      await Crud_Catalogos(
        'softphone/' + usuario.id,
        'usuarios',
        'get',
        '',
        { username: this.state.username },
        '',
        'softphone'
      )
        .then((softphone) => {
          if (softphone.id && softphone.estatus) {
            sessionStorage.setItem('softphone', JSON.stringify(softphone));
            window.open(
              `${window.location.origin}/telefono/${tokenGetUserName(
                resp.token
              )}`,
              'telefono-' + tokenGetUserName(resp.token),
              'width=500,height=500'
            );
            Crud_Catalogos(
              'softphone/loguear_en_grupo',
              'softphone',
              'post',
              '',
              {},
              '',
              'softphone'
            )
              .then((data) => {
                NotificationManager.success(data.message, 'Telefono');
              })
              .catch((err) => console.log(err));
          } else {
            sessionStorage.setItem('softphone', JSON.stringify({}));
          }
        })
        .catch((err) => console.log(err));
      let moduloNaviero = false;

      await Crud_Catalogos(
        'bloque/usuario/' + usuario.id + '/perfil/' + perfil.id,
        'usuarios',
        'get',
        '',
        '',
        '',
        'currentUser'
      ).then((bloques) => {
      
        bloques.map((item, index) => {
          console.log(item.nombre);
          switch (item.nombre) {
            case 'Dashboard':
              {
                dashboardSubItems.push({
                  value: 'Dashboard GPS estus actual',
                  to: '/dashboard_GPS',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'dashboard_gps_year':
              {
                dashboardSubItems.push({
                  value: 'Dashboard GPS nuevos por año',
                  to: '/dashboard_gps_year',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'dashboard_gps_estatus':
              {
                dashboardSubItems.push({
                  value: 'Dashboard GPS envíos',
                  to: '/dashboard_gps_estatus',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'dashboard_gps_recuperar':
              {
                dashboardSubItems.push({
                  value: 'Dashboard GPS recuperar',
                  to: '/dashboard_gps_recuperar',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Dashboard_Terrestre':
              {
                dashboardSubItems.push({
                  value: 'Dashboard Terrestre',
                  to: '/dashboardTerrestre',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Dashboard_Naviera':
              {
                dashboardSubItems.push({
                  value: 'Dashboard Naviera',
                  to: '/dashboardNaviera',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Dashboard_Alertas_Terrestre_Qlik':
              {
                dashboardSubItems.push({
                  value: 'Dashboard Alertas Terrestre',
                  to: '/qlik/dashboardAlertasTerrestre',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Dashboard_Alertas_Naviera_Qlik':
              {
                dashboardSubItems.push({
                  value: 'Dashboard Alertas Naviera',
                  to: '/qlik/dashboardAlertasNaviera',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Dashboard_Llamadas_Por_Estatus_Qlik':
              {
                dashboardSubItems.push({
                  value: 'Dashboard Llamadas por Estatus',
                  to: '/qlik/dashboardLlamadasPorEstatus',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Dashboard_Llamadas_Por_Tipo_Qlik':
              {
                dashboardSubItems.push({
                  value: 'Dashboard Llamadas por Tipo',
                  to: '/qlik/dashboardLlamadasPorTipo',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'tablero_captura_en_tiempo':
              {
                dashboardSubItems.push({
                  value: 'Tablero Captura En Tiempo',
                  to: '/tableroCapturaEnTiempo',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'correos_programados':
              {
                dashboardSubItems.push({
                  value: 'Correos programados',
                  to: '/correosProgramados',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;

            case 'mapa_de_gps':
              {
                mapasSubItems.push({
                  value: "Mapa Gps's",
                  to: '/qlik/mapaGps',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;

            case 'mapa_de_riesgo':
              {
                mapasSubItems.push({
                  value: 'Mapa de Riesgo',
                  to: '/mapaDeRiesgo',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;

            case 'Almacen':
              {
                monitoreoSubItems.push({
                  value: 'GPSs Almacén',
                  to: '/gps-almacen',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;

            case 'terminado_masivo_egoba':
              {
                monitoreoSubItems.push({
                  value: 'Terminado Masivo',
                  to: '/terminadoMasivo',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Administracion':
              {
                navBarItems.push({
                  value: 'Administración',
                  icon: 'layers',
                  to: '/adminstracion',
                  LinkComponent: withRouter(NavLink),
                  useExact: true,
                });
                Administracion = true;
              }
              break;
            case 'movimientos_nav_clien':
              {
                navBarItems.push({
                  value: 'Servicios',
                  icon: 'layers',
                  to: '/movimientos_nav_clien',
                  LinkComponent: withRouter(NavLink),
                  useExact: true,
                });
              }
              break;
            case 'Monitoreo_Terrestre':
              {
                //creamos menu modulo monitore aqui
                modulosMonitoreoSubItems.push({
                  value: 'Monitoreo Todos',
                  icon: 'cast',
                  to: '/monitoreo?mtipo=0',
                  LinkComponent: withRouter(NavLink),
                  useExact: true,
                });
              }
              break;
            case 'Monitoreo_Naviero':
              moduloNaviero = true;
              break;
            case 'Reportes':
              reportesSubItems.push({
                value: 'Reportes',
                icon: 'file-text',
                to: '/reportes',
                LinkComponent: withRouter(NavLink),
                useExact: true,
              });
              break;
            case 'Reportes_Generales':
              reportesSubItems.push({
                value: 'Reportes Generales',
                icon: 'file-text',
                to: '/reportesGenerales',
                LinkComponent: withRouter(NavLink),
                useExact: true,
              });
              break;
            case 'reporte_llamadas':
              reportesSubItems.push({
                value: 'Grabaciones de llamadas',
                icon: 'file-text',
                to: '/reporte_llamdas',
                LinkComponent: withRouter(NavLink),
                useExact: true,
              });
              break;
            case 'reporte_alarmas_cctv':
              reportesSubItems.push({
                value: 'Alarmas CCTV',
                icon: 'file-text',
                to: '/reporte_alarmas_cctv',
                LinkComponent: withRouter(NavLink),
                useExact: true,
              });
              break;
            case 'Consola_Naviera':
              {
                monitoreoSubItems.push({
                  value: 'Usuario Consola Navieros',
                  to: '/usuarioConsola',
                  LinkComponent: withRouter(NavLink),
                });
                consultasSubItems.push({
                  value: 'Bitacora de Catalogos',
                  to: '/catalogos_log',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'chatbot':
              consultasSubItems.push({
                value: 'Chatbot',
                to: '/chatbot',
                LinkComponent: withRouter(NavLink),
              });
              break;
            case 'Consola_Terrestre':
              {
                monitoreoSubItems.push({
                  value: 'Usuario Consola Terrestres',
                  to: '/usuarioTerrestre',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Asignar_GPS':
              {
                monitoreoSubItems.push({
                  value: 'Asignar GPS',
                  to: '/movimientoGps',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Movimientos Patios':
              {
                monitoreoSubItems.push({
                  value: 'Movimientos Patios',
                  to: '/movimientosPatio',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Recuperar_GPS':
              {
                monitoreoSubItems.push({
                  value: 'Recuperar GPS',
                  to: '/recuperarGps',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Unidades_sin_ruta':
              {
                monitoreoSubItems.push({
                  value: 'Unidades Sin Ruta',
                  to: '/unidadessinruta',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Modulo_de_Alertas':
              {
                monitoreoSubItems.push({
                  value: 'Modulo Alertas',
                  to: '/alertasunidadesenruta',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;

            //dashboard_gps_estatus
            case 'gps_cambiar_estatus':
              {
                monitoreoSubItems.push({
                  value: 'Gps envío',
                  to: '/gps_envio',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'SkyCold':
              {
                monitoreoSubItems.push({
                  value: 'Sky Cold',
                  to: '/skycold',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'catalogos_log':
              {
                consultasSubItems.push({
                  value: 'Sky Cold',
                  to: '/skycold',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'Estatus_Gerenciador':
              {
                monitoreoSubItems.push({
                  value: 'Estatus Gerenciador',
                  to: '/gerenciador',
                  LinkComponent: withRouter(NavLink),
                });
              }
              break;
            case 'DRP':
              {
                navBarItems.push({
                  value: 'DRP',
                  icon: 'settings',
                  to: '/drp',
                  LinkComponent: withRouter(NavLink),
                  useExact: true,
                });
                Administracion = true;
              }
              break;
            case 'edicion_modulo_arrastre':
              moduloArrastreSubItems.push({
                value: 'Logistica Remolque',
                icon: 'layers',
                to: '/update_remolques',
                LinkComponent: withRouter(NavLink),
                useExact: true,
              });
              break;
            // case 'reporte_modulo_arrastre':
            //     moduloArrastreSubItems.push({
            //       value: 'Reportes',
            //       icon: 'layers',
            //       to: '/reporte_remolques',
            //       LinkComponent: withRouter(NavLink),
            //       useExact: true,
            //     });
            // break;
              case 'Noticias':              ;

              navBarItems.push({
                value: 'Noticias',
                icon: 'layers',
                to: '/noticias',
                LinkComponent: withRouter(NavLink),
                useExact: true,
              });
              break;
            case 'Monitoreo_OTD':
              monitoreoSubItems.push({
                value: 'Monitoreo OTD',
                icon: 'layers',
                to: '/monitoreoOTD',
                LinkComponent: withRouter(NavLink),
                useExact: true,
              });
              break;

            default:
              break;
          }
        });
      });
      if (moduloNaviero) {
        //navBarItems.push({ value:  "Monitoreo Todos", icon:"cast",                 to:"/monitoreo?mtipo=0",LinkComponent:withRouter(NavLink), useExact:true});
        let bandera = false;
        for (let op of modulosMonitoreoSubItems) {
          if (op.value === 'Monitoreo Todos') bandera = true;
        }
        if (!bandera)
          modulosMonitoreoSubItems.push({
            value: 'Monitoreo Todos',
            icon: 'cast',
            to: '/monitoreo?mtipo=0',
            LinkComponent: withRouter(NavLink),
            useExact: true,
          });
        await Crud_Catalogos(
          'movimientosTipo/usuarioCorredores/' + usuario.id,
          'usuarios',
          'get',
          '',
          {},
          '',
          'movimientosTipo'
        ).then((movimientosTipo) => {
          console.log(movimientosTipo)
          /**
           * gpsTipo=1 Con GPS's
           * gpsTipo=2 Sin GPS's
           * gpsTipo=3 Pendientes de finalizar (No cumplio o sin equipo sky)
           */
          for (let mtipo of movimientosTipo.sort()) {
            console.log(mtipo)

            if(!this.state.banderaPersonalizada){
                 modulosMonitoreoSubItems.push({
                  value: mtipo.nombre,
                  icon: 'cast',
                  to: '/monitoreo?mtipo=' + mtipo.id,
                  LinkComponent: withRouter(NavLink),
                  useExact: true,
                  });
              modulosMonitoreoSubItems.push({
                value: mtipo.nombre + " (movimientos con GPS)",
                icon: 'cast',
                to: '/monitoreo?mtipo=' + mtipo.id + "&gpsTipo=2",
                LinkComponent: withRouter(NavLink),
                useExact: true,
              });
            }
         
          }

          if(!this.state.banderaPersonalizada){
            modulosMonitoreoSubItems.push({
              value: "Movimientos sin GPS",
              icon: 'cast',
              to: '/monitoreo?mtipo=0&gpsTipo=1',
              LinkComponent: withRouter(NavLink),
              useExact: true,
            });
            modulosMonitoreoSubItems.push({
              value: "Movimientos pendientes de finalizar",
              icon: 'cast',
              to: '/monitoreo?mtipo=0&gpsTipo=3',
              LinkComponent: withRouter(NavLink),
              useExact: true,
            });
          }else{
            modulosMonitoreoSubItems.push({
              value: "Movimientos sin GPS",
              icon: 'cast',
              to: '/monitoreo?mtipo=0&gpsTipo=1',
              LinkComponent: withRouter(NavLink),
              useExact: true,
            });
            modulosMonitoreoSubItems.push({
              value: "Movimientos con gps",
              icon: 'cast',
              to: '/monitoreo?mtipo=0&gpsTipo=2',
              LinkComponent: withRouter(NavLink),
              useExact: true,
            });
          }
        });
      }
      /**
       * Cuando no se tiene acceso a dashboard, administracion, redirigira a monitoreo , consola naviera, consola terrestre, asignar gps, recuperar gps, unidades sin ruta, skycold, en ese orden
       */
      let redirect = '';
      if (modulosMonitoreoSubItems.length > 0) {
        navBarItems.push({
          value: 'Modulos Monitoreo',
          icon: 'cast',
          subItems: modulosMonitoreoSubItems,
        });
      }
      if (consultasSubItems.length > 0) {
        navBarItems.push({
          value: 'Consultas',
          icon: 'settings',
          subItems: consultasSubItems,
        });
        //monitoreo = true;
      }
      if (dashboardSubItems.length > 0) {
        navBarItems.push({
          value: 'Dashboard',
          icon: 'bar-chart-2',
          subItems: dashboardSubItems,
        });
        Dashboard = true;
      }
      if (mapasSubItems.length > 0) {
        navBarItems.push({
          value: 'Mapas',
          icon: 'map',
          subItems: mapasSubItems,
        });
        Dashboard = true;
      }
      if (monitoreoSubItems.length > 0) {
        navBarItems.push({
          value: 'Monitoreo',
          icon: 'settings',
          subItems: monitoreoSubItems,
        });
        monitoreo = true;
      }
      if (moduloArrastreSubItems.length > 0) {
        navBarItems.push({
          value: 'Modulo Arrastre',
          icon: 'settings',
          subItems: moduloArrastreSubItems,
        });
      }
      if (reportesSubItems.length > 0) {
        navBarItems.push({
          value: 'Reportes',
          icon: 'layers',
          subItems: reportesSubItems,
        });
        monitoreo = true;
      }
      for (let item of monitoreoSubItems) {
        if (item.value === 'Monitoreo') {
          redirect = '/monitoreo';
          break;
        }
        if (item.value === 'Usuario Consola Navieros') {
          redirect = '/usuarioConsola';
          break;
        }
        if (item.value === 'Usuario Consola Terrestres') {
          redirect = '/usuarioTerrestre';
          break;
        }
        if (item.value === 'Asignar GPS') {
          redirect = '/movimientoGps';
          break;
        }
        if (item.value === 'Movimientos Patio') {
          redirect = '/movimientosPatio';
          break;
        }
        if (item.value === 'Recuperar GPS') {
          redirect = '/recuperarGps';
          break;
        }
        if (item.value === 'Unidades Sin Ruta') {
          redirect = '/unidadessinruta';
          break;
        }
        if (item.value === 'Alertas Unidades en Ruta') {
          redirect = '/alertasunidadesenruta';
          break;
        }
        if (item.value === 'Sky Cold') {
          redirect = '/skycold';
          break;
        }
        if (item.value === 'GPSs Almacén') {
          redirect = '/gps-almacen';
          break;
        }
        if (item.value === 'Modulo Alertas') {
          redirect = item.to;
          break;
        }
      }
      for (let item of navBarItems) {
        if (item.value === 'Modulos Monitoreo') {
          redirect = '/monitoreo?mtipo=0';
          monitoreo = true;
          break;
        }
        if (item.value === 'Importación') {
          redirect = '/monitoreoimpo';
          monitoreo = true;
          break;
        }
        if (item.value === 'Exportación') {
          redirect = '/monitoreoexpo';
          monitoreo = true;
          break;
        }
        if (item.value === 'Servicios') {
          redirect = '/movimientos_nav_clien';
          break;
        }
      }
      for (let item of navBarItems) {
        if (item.value === 'Modulos Monitoreo') {
          redirect = '/monitoreo?mtipo=0';
          monitoreo = true;
        }
      }
      navBarItems.sort(this.dynamicSort('value'));
      sessionStorage.setItem('navBarItems', JSON.stringify(navBarItems));
      //window.location.href = "/adminstracion";
      //Se ordenan los valores del navbar

      if (monitoreo) window.location.href = redirect;
      if (Administracion) window.location.href = '/adminstracion';
      if (redirect === '/movimientos_nav_clien')
        window.location.href = redirect;
      if ((!redirect !== redirect) === '') window.location.href = '/login';
    } else {
      this.setState({ message: 'Usuario o contraseña incorrectos' });
      this.setState({ showmessage: true });
      this.setState({ username: '' });
      this.setState({ password: '' });
      this.setState({ loginMessage: null });
    }
    loading = false;
  };

  dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };
  screenWidth = () => {
    //console.log("screen with: "+ window.screen.width)
    if (window.screen.width > 900) {
      return 4;
    } else {
      return 0;
    }
  };
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.getLogin();
    }
  };
  render() {
    const { values, strings = {} } = this.props;
    return (
      <Grid.Col sm={12} lg={4} offset={this.screenWidth()} alignItems="center">
        <div className="p-4"></div>
        <Card>
          <Card.Body>
            <div className="col-12 text-center">
              <img src="images/allone.svg" width="100" height="50" />
            </div>
            <div
              className="col-12 text-center"
              hidden={this.state.loginMessage}
            >
              {strings.title || defaultStrings.title}
            </div>
            <div hidden={!this.state.showmessage}>
              <Alert
                type="danger"
                icon="alert-triangle" //onDismissClick={ this.screenWidth }  isDismissible={true}
              >
                {this.state.message}
              </Alert>
            </div>

            <div hidden={!this.state.loginMessage} style={{ height: '40vh' }}>
              <h1 className="mt-4">{this.state.loginMessage}</h1>
              <Dimmer active={true} loader></Dimmer>
            </div>
            <div hidden={this.state.loginMessage}>
              <FormTextInput
                name="username"
                label={defaultStrings.usernameLabel}
                placeholder={defaultStrings.usernamePlaceholder}
                onChange={this.changeUsername}
                //onBlur={onBlur}
                value={this.state.username}
                //error={errors && errors.email}
              />
              <FormTextInput
                name="password"
                type="password"
                label={strings.passwordLabel || defaultStrings.passwordLabel}
                placeholder={
                  strings.passwordPlaceholder ||
                  defaultStrings.passwordPlaceholder
                }
                onKeyDown={this._handleKeyDown}
                onChange={this.changePassword}
                //onBlur={onBlur}
                value={this.state.password}
                //error={errors && errors.password}
              />
              <div className="col-12 text-right">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  onClick={() => this.getLogin()}
                >
                  <span class="badge">
                    <Icon link={true} name="user" />
                    Login
                  </span>
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Grid.Col>
    );
  }
}

export default LoginPage;
