import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ConsolaTelefono from "../ConsolaTelefono/ConsolaTelefono";
import JsSIP from "react-native-jssip";
import { user_sipinfo } from "../globalPhoneVariables";
import TelefonoMensajeria from "../../../socket/TelefonoMensajeria";
import Smartphone from "../SmartPhone";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";

const Telefono = ({ match }) => {
  /** -----DEBUG MODE----- */
  JsSIP.debug.enable("JsSIP:*");

  /** -----Variables de control del teléfono----- */
  const socket = useRef();
  const configuration = useRef();
  const userAgent = useRef();
  const session = useRef();
  const [logs, setLogs] = useState([]);
  const [connection, setConnection] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("usuario"));
  const [configReady, setConfigReady] = useState(false);

  /** Declaracion de variables de configuracion */
  const [userDatos, setUserDatos] = useState({
    username: "",
    authuser: "",
    secret: "",
  });
  /** -----Variables para los mensajes de websocket----- */
  const [mensaje, setMensaje] = useState({
    id: "RECIBER",
    subject: "",
    msg: {},
  });
  const [numero, setNumero] = useState("");
  const [llamar, setLlamar] = useState(false);
  const [colgar, setColgar] = useState(false);
  const [transfer, setTransfer] = useState(null);
  const [contestar, setContestar] = useState(false);
  const [ignorar, setIgnorar] = useState(false);
  const [enLlamada, setEnLlamada] = useState(false);
  const [yourusername, setYourUserName] = useState(null);
  const [configuracion, setConfiguracion] = useState({});

  const registerLog = (message) => {
    setLogs((prev) => [...prev, message]);
  };

  //Funcion para agregar el audio
  const attachRemoteStream = useCallback((session) => {
    // Fetch the existing audio element or create a new one
    const remoteAudio = document.getElementById("remoteAudio");
    session.connection.onaddstream = (event) => {
      registerLog("Inicia stream del audio");
      remoteAudio.srcObject = event.stream;
      remoteAudio.play();
    };
  }, []);
  /** -----Definición de handlers para los eventos de llamada----- */
  // Llamada en progreso
  const handleCallInProgress = useCallback((event) => {//alert("llamada en progreso");
    registerLog("Llamada en progreso");
  }, []);

  //Falla en la llamada
  const handleCallFailed = useCallback((event) => {
    if (event.cause === "User Denied Media Access") {
      registerLog(
        "No se cuenta con micrófono / No se tiene permiso para acceder a él"
      );
      return;
    }
    console.log(event);
    registerLog("Error en la llamada");
    if (event.cause === "Canceled") {
      registerLog("Canceled");
      setMensaje((prevM) => {
        return {
          ...prevM,
          subject: "CANCELED",
          msg: { contacto: "" },
        };
      });
    }
  }, []);
  //Llamada terminada
  const handleCallEnd = useCallback((event) => {//alert("llamada terminada");
    registerLog("Llamada terminada");
    setMensaje((prevM) => {
      return {
        ...prevM,
        subject: "HANGING UP",
        msg: { contacto: "" },
      };
    });
  }, []);
  //Llamada contestada
  const handleCallAnswered = useCallback((event) => {console.log("Llamada recibida por receptor*********************");
    registerLog("Llamada recibida por receptor");
  }, []);
  // Actualización del estado de la llamada
  const handleCallUpdate = useCallback((event) => {
    console.log(event);
  }, []);

  //Registro de los callbacks para eventos de llamada.
  const eventHandlers = useMemo(
    () => ({
      progress: (e) => handleCallInProgress(e),
      failed: (e) => handleCallFailed(e),
      ended: (e) => handleCallEnd(e),
      confirmed: (e) => handleCallAnswered(e),
      update: (e) => handleCallUpdate(e),
    }),
    [
      handleCallInProgress,
      handleCallFailed,
      handleCallEnd,
      handleCallAnswered,
      handleCallUpdate,
    ]
  );

  /** -----Definición de handlers para los eventos de transferencia----- */
  // Éxito en transferencia
  const handleTranferSuccess = (e) => {
    console.log(e);
    registerLog("Transferencia iniciada");
  };
  const handleTransferFailed = (e) => {
    console.log(e);
    registerLog("Transferencia erronea");
  };
  const handleTransferSending = (e) => {
    console.log(e);
    registerLog("Realizando la transferencia...");
  };
  const handleTransferInProcess = (e) => {
    console.log(e);
    registerLog("Transferencia en proceso");
  };
  const handleTransferAccepted = (e) => {
    console.log(e);
    setMensaje((prevM) => {
      return {
        ...prevM,
        subject: "TRANSFER COMPLETED",
        msg: { contacto: "" },
      };
    });
    registerLog("Transferencia aceptada");
  };

  //Registro de los callbacks para eventos de transferencia.
  const eventHandlersForTransfer = {
    requestSucceeded: handleTranferSuccess,
    requestFailed: handleTransferFailed,
    trying: handleTransferSending,
    progress: handleTransferInProcess,
    accepted: handleTransferAccepted,
  };

  //Objeto de configuracion de handlers para llamada
  const options = {
    eventHandlers: eventHandlers,
    mediaConstraints: { audio: true, video: false },
    extraHeaders: [
      `GroupID: ${configuracion.grupo}`,
      `GroupName: ${"Grupo"}`,
      `AgentID: ${configuracion.idPbx}`,
    ],
  };

  /** -----Definición de handlers para los eventos de llamada----- */
  //Cuando el telefono se esta conectando
  const handleConnectingToWebSocket = useCallback((event) => {
    registerLog("Conectando al websocket...");
  }, []);
  //Cuando el telefono esta conectado
  const handleConnectedToWebocket = useCallback((event) => {
    registerLog("Teléfono conectado al websocket");
  }, []);
  //Cuando el telefono se desconecta
  const handleDisconnectionToWebsocket = useCallback((event) => {
    registerLog("Desconectado del websocket, sucedió un error");
    setConnection(false);
  }, []);
  //Cuando se registra al usuario
  const handleUserRegistered = useCallback((event) => {
    setConnection(true);
    registerLog("Usuario registrado");
  }, []);
  //Cuando se dio de baja al usuario
  const handleUserUnregistered = useCallback((event) => {
    registerLog("Usuario dado de baja");
    setConnection(false);
  }, []);
  //Cuando sucede una falla al registrar usuario
  const handleUserRegistrationFailed = useCallback((event) => {
    registerLog("El registro del usuario falló");
    setConnection(false);
  }, []);
  //Cuando el usuario recibe/envia llamada
  const handleCall = useCallback(
    (e) => {
      try{
        session.current = e.session;
        if (session.current.direction === "incoming") {
          //Se agregan event handlers a llamada remota
          session.current._events = eventHandlers;
          registerLog("Incoming call");
          const callerNumber = session.current.remote_identity.uri._user;
          registerLog(`Identidad del agente remoto: ${callerNumber}`);
          //Abrir modal
          setMensaje((prevM) => {
            return {
              ...prevM,
              subject: "INCOMMING CALL",
              msg: {
                showModal: true,
                contacto: callerNumber,
              },
            };
          });
        } else if (session.current.direction === "outgoing") {
          registerLog("Outgoing call");
          attachRemoteStream(session.current);
        }
      } catch (err) {
        console.log("catch Cuando el usuario recibe/envia llamada" + err);
      }
      
    },
    [attachRemoteStream]
  );
  //Cuando se recibe un mensaje de la llamada
  const handleCallMessage = useCallback((e) => {
    registerLog("MENSAJE");
    console.log(e);
  }, []);
  //Cuando el usuario recibe un evento SIP
  const handleSIPEvent = useCallback((e) => {
    registerLog("evento");
    console.log(e);
  }, []);

  /** -----Registro de eventos de la sesión----- */
  const setSessionEvents = useCallback(() => {
    userAgent.current.on("connecting", handleConnectingToWebSocket);
    userAgent.current.on("connected", handleConnectedToWebocket);
    userAgent.current.on("disconnected", handleDisconnectionToWebsocket);
    userAgent.current.on("registered", handleUserRegistered);
    userAgent.current.on("unregistered", handleUserUnregistered);
    userAgent.current.on("registrationFailed", handleUserRegistrationFailed);
    userAgent.current.on("newRTCSession", handleCall);
    userAgent.current.on("newMessage", handleCallMessage);
    userAgent.current.on("sipEvent", handleSIPEvent);
  }, [
    handleConnectingToWebSocket,
    handleCall,
    handleCallMessage,
    handleConnectedToWebocket,
    handleUserRegistered,
    handleUserUnregistered,
    handleUserRegistrationFailed,
    handleSIPEvent,
    handleDisconnectionToWebsocket,
  ]);

  /** ----- useEffect ----- */
  // Para poner los datos del usuario
  useEffect(() => {
    if (configuracion.id && configuracion.estatus) {
      setUserDatos({
        username: "sip:" + configuracion.username + "@" + user_sipinfo.domain,
        authuser: configuracion.username,
        secret: configuracion.password,
      });
      setConfigReady(true);
    }
  }, [configuracion]);

  useEffect(() => {
    if (!userAgent.current && userDatos.username) {
      try{
        socket.current = new JsSIP.WebSocketInterface(user_sipinfo.websocket);
        configuration.current = {
          sockets: [socket.current],
          uri: userDatos.username,
          password: userDatos.secret,
          display_name: userDatos.authuser,
        };
        console.log(configuration.current);
        userAgent.current = new JsSIP.UA(configuration.current);
        //Establece los eventos de la sesion
        setSessionEvents();
        userAgent.current.start();
      } catch (err) {
        console.log("catch Establece los eventos" + err);
      }
    }
  }, [setSessionEvents, userDatos]);

  useEffect(() => {
    if (mensaje.subject !== "") {
      setMensaje({ ...mensaje, subject: "" });
    }
  }, [mensaje.subject]);

  // Se detecta cuando el usuario oprime la tecla para llamar
  useEffect(() => {
    if (llamar) {
      console.log("LLAMAR");
      callUser();
      setLlamar(false);
    }
  }, [llamar]);

  // Se detecta cuando el usuario oprime la tecla de colgar
  useEffect(() => {
    if (colgar) {
      console.log("COLGAR");
      hungUpCall();
      setColgar(false);
    }
  }, [colgar]);

  useEffect(() => {
    if (transfer) {
      blindTransferCall(transfer);
    }
  }, [transfer]);

  useEffect(() => {
    if (contestar) {
      answerIncommingCall();
      setContestar(false);
    }
  }, [contestar]);

  useEffect(() => {
    if (ignorar) {
      hungUpCall();
      setIgnorar(false);
    }
  }, [ignorar]);

  //Para traer los datos del softphone del usuario
  useEffect(() => {
    getfuntion("get", "", "", "softphone/" + user.id, "configuracion");
  }, []);

  /** ----- Acciones en el teléfono ----- */
  //Funcion para llamar a un usuario
  const callUser = () => {
    try{
      if(userAgent.current){
        registerLog("Llamar: Se ha solicitado la llamada");
        userAgent.current.call(`sip:${numero}@${user_sipinfo.domain}`, options);
      }else{
        console.log("colgar, no hay userAgent");
      }
    } catch (err) {
      console.log("catch llamar a un usuario" + err);
    }
  };

  //Funcion para colgar
  const hungUpCall = () => {
    try{
      if(session.current){
        registerLog("Colgar: Solicitud de llamada terminada");
        session.current.terminate();
        setEnLlamada(false);
        setYourUserName("");
        setTransfer(null);
      }else{
        console.log("colgar, no hay session");
      }
    } catch (err) {
      console.log("catch colgar llamada" + err);
    }
  };

  // Funcion para responder una llamada entrante
  const answerIncommingCall = () => {
    try{
      if(session.current){
        const callOptions = {
          mediaConstraints: {
            audio: true,
            video: false,
          },
          pcConfig: {
            iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }],
            iceTransportPolicy: "all",
            rtcpMuxPolicy: "negotiate",
          },
        };
        registerLog("Se contesta la llamada");
        //console.log(session.current);
        session.current.answer(callOptions);
        attachRemoteStream(session.current);
      }else{
        console.log("responder llamada, no hay session");
      }
    } catch (err) {
      console.log("catch responder llamada" + err);
      setEnLlamada(false);
      setYourUserName("");
      setTransfer(null);
      registerLog("Llamada terminada");
      setMensaje((prevM) => {
        return {
          ...prevM,
          subject: "HANGING UP",
          msg: { contacto: "" },
        };
      });
    }
  };

  //Funcion para transferencia a ciegas
  const blindTransferCall = (numeroATransferir) => {
    try {
      if(session.current){
        session.current.refer(`sip:${numeroATransferir}@${user_sipinfo.domain}`, {
          eventHandlers: eventHandlersForTransfer,
          extraHeaders: [
            `Contact: < ${userDatos.username}>`,
            `GroupID: ${configuracion.grupo}`,
            `GroupName: ${"Grupo"}`,
            `AgentID: ${configuracion.idPbx}`,
          ],
        });
        setTransfer(null);
      }else{
        console.log("transferencia, no hay session");
      }
    } catch (err) {
      console.log("catch transferencia" + err);
      registerLog("No fue posible realizar la transferencia");
    }
  };
  //Funcion para controlar micrófono
  const toggleMute = () => {
    try {
      if(session.current){
        if (session.current.isMuted().audio) {
          session.current.unmute();
          registerLog("Micrófono encendido");
        } else {
          registerLog("Micrófono apagado");
          session.current.mute();
        }
      }else{
        console.log("mute, no hay session");
      }
    } catch (err) {
      console.log("catch mute" + err);
    }
  };

  //
  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "usuario",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "configuracion") {
              if (returnVal.id) {
                setConfiguracion(returnVal);
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <TelefonoMensajeria
        origen = "RECIBER"
        topic={[`/topic/telefono-${match.params.usuario}`]}
        mensaje={mensaje}
        actualValues={{ user: yourusername, enllamada: enLlamada }}
        setMensaje={setMensaje}
        setNumero={setNumero}
        setLlamar={setLlamar}
        setColgar={setColgar}
        setTransfer={setTransfer}
        toggleMute={toggleMute}
        setContestar={setContestar}
        setIgnorar={setIgnorar}
        usuario={match.params.usuario}
      />
      <Smartphone
        llamar={callUser}
        enLlamada={enLlamada}
        colgar={hungUpCall}
        llamante={yourusername}
        numero={numero}
      />
      <ConsolaTelefono logs={logs} isConnected={connection} />
      <audio id="remoteAudio"></audio>
    </>
  );
};

export default Telefono;
