import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { CatalogosLogPage } from "../../../pages";


var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class CuentaEspejo extends Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      //Campos de la Entity
      idCuentaEspejo: "",
      idPlataforma:this.props.plataforma.id,
      nombre: "",
      url: "",
      usuario: "",
      password: "",
      credenciales: false,
      crudbutonEnable: true,
      accion: this.props.accion
    };

    this.cuentaEspejoObject = {
      idCuentaEspejo: 0,
      nombre: "",
      url: "",
      usuario: "",
      password: "",
      credenciales: "",
      idPlataforma: ""
   
    };
 
    this.plataformaId = 0;

  }
  isEnable = (Accion) => {
    let borrar = "cuentasEspejo_borrar";
    let actualizar = "cuentasEspejo_editar";
    let agregar = "cuentasEspejo_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  componentDidMount = () => {
    if (this.props.accion !== "nuevo") {
      this.cuentaEspejoObject = this.props.elementos;
      this.setState({
        idCuentaEspejo: this.props.elementos.idCuentaEspejo ? this.props.elementos.idCuentaEspejo : "",
      });
      this.setState({
        nombre: this.props.elementos.nombre ? this.props.elementos.nombre : "",
      });
      this.setState({
        url: this.props.elementos.url
          ? this.props.elementos.url
          : "",
      });
      this.setState({
        usuario: this.props.elementos.usuario
          ? this.props.elementos.usuario
          : "",
      });
      this.setState({
        password: this.props.elementos.password
          ? this.props.elementos.password
          : "",
      });
      this.setState({
        credenciales: this.props.elementos.credenciales
          ? this.props.elementos.credenciales
          : "",
      });
    

    }
  
   
  };
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };
 

  enableSave = () => {
    if (
      this.state.nombre.length > 0 &&
      this.state.url.length > 0 &&
      (!this.state.credenciales) || 
      (this.state.credenciales && this.state.usuario.length > 0 && this.state.password.length > 0)
    ) {
      return false;
    } else {
      return true;
    }
  };
  guardarCuentaEspejo = () => {
    console.log("cuentas espejo" , this.state.accion)
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  delete() {
    this.props.getfuntion("delete", "", this.state.id);
    this.setState({ crudbutonEnable: false });
  }
  crud_put() {
    console.log("Entro acacac put")
    this.props.getfuntion("put", this.cuentaEspejoObject);
    this.setState({ crudbutonEnable: false });
  }
  crud_post() {
    this.cuentaEspejoObject.idPlataforma = this.state.idPlataforma;
    this.props.getfuntion("post", this.cuentaEspejoObject);
    this.setState({ crudbutonEnable: false });
  }
  changeNombre = (event) => {
    this.cuentaEspejoObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  };
  changeUrl = (event) => {
    this.cuentaEspejoObject.url = event.target.value;
    this.setState({ url: event.target.value });
  };
  changeUsuario = (event) => {
    this.cuentaEspejoObject.usuario = event.target.value;
    this.setState({ usuario: event.target.value });
  };
  changeContraseña = (event) => {
    this.cuentaEspejoObject.password = event.target.value;
    this.setState({ password: event.target.value });
  };

  changeCredenciales = (event) => {
    this.cuentaEspejoObject.credenciales = event.target.checked ? 1 : 0;
    this.setState({ credenciales: event.target.checked ? 1 : 0 });
  };

 

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1> {this.props.elementos.nombre} </h1>
        ) : null}
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <Card
              title="Cuenta Espejo"
              isCollapsible
              isCollapsed={false}
              body={
                <div>
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Group label="id">
                        <Form.Input
                          name="id"
                          disabled
                          type="number"
                          value={this.state.id}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="nombre">
                        <Form.Input
                          name="nombre"
                          type="text"
                          value={this.state.nombre}
                          onChange={this.changeNombre}
                          maxlength="40"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Url">
                        <Form.Input
                          name="url"
                          type="text"
                          value={this.state.url}
                          onChange={this.changeUrl}
                          maxlength="20"
                        />
                      </Form.Group>
                    </Grid.Col>

                    <Grid.Col>
                          <Form.Group label="¿Credenciales?">
                            <Form.Switch
                              type="Checkbox"
                              name="credenciales"
                              checked={this.state.credenciales}
                              onChange={this.changeCredenciales}
                            />
                          </Form.Group>
                        </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>

                    
                    <Grid.Col md={6} lg={6}>
                    {this.state.credenciales ? ( 
                      <Form.Group label="Usuario">  
                        <Form.Input
                          name="usuario"
                          type="text"
                          value={this.state.usuario}
                          onChange={this.changeUsuario}
                          maxlength="40"
                        />
                     
                      </Form.Group>
                       ) : null}
                    </Grid.Col>
                    <Grid.Col>
                    {this.state.credenciales ? (  
                      <Form.Group label="contraseña">
                        <Form.Input
                          name="contraseña"
                          type="text"
                          value={this.state.password}
                          onChange={this.changeContraseña}
                          maxlength="20"
                        />
                      </Form.Group>
                      ) : null}
                    </Grid.Col>
     
                    
                  </Grid.Row>
                </div>
              }
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <div className="float-right">
              {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
                <span class="badge">
                  <Button
                    target="_blank"
                    size="sm"
                    RootComponent="a"
                    color="primary"
                    disabled={this.enableSave() || !this.state.crudbutonEnable}
                    onClick={this.guardarCuentaEspejo}
                  >
                    <span class="badge">
                      <Icon link={true} name="save" />
                      Guardar
                    </span>
                  </Button>
                </span>
              ) : null}
              {this.state.accion === "modificar" && this.isEnable("borrar") ? (
                <span class="badge">
                  <Button
                    target="_blank"
                    size="sm"
                    RootComponent="a"
                    color="primary"
                    onClick={() => this.delete()}
                    disabled={!this.state.crudbutonEnable}
                  >
                    <span class="badge">
                      <Icon link={true} name="trash" />
                      Borrar
                    </span>
                  </Button>
                </span>
              ) : null}
            </div>
          </Grid.Col>
        </Grid.Row>
        {/**
         *   Elementos de plataformas
         */}
      </div>
    );
  }
}

export default CuentaEspejo;
