import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import Select from 'react-select';
import Generador from "./Plantilla/Generador.react";
import { isArray, isObject } from "util";
import ModalLayout from "../../../../components/ModalLayout.react";
import { CorreoPlantilla } from "../correo/index.js";
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';

class  Plantillas extends Component {
  constructor(props) {
    super(props);
    this.state = {
                      id: "",
                      hiddenModals: false,
                      acumulador: { value: 0, label: "Selecciona un acumulador ", indice: 0 },
                      correoPlantillas: [],
                      movimientosTipo: [],
                      camposLogisticos: [],
                      idCampoLogistico: { value: 0, label:" Seleccione un campoLogistico"},
                      plantilla: "",
                      acumuladores: "",
                      plantillas: [],
                      getPlantilla:"",
                      currentplantilla: { value: 0, label: "Selecciona una plantilla ", indice: 0 },
                      idMovimientoTipo: { value: 0 , label: "Selecione tipo de movmiento" },
                      cron: "00:00:00",
                      configuracionesPlantillaConsolidado: [],
                      mensaje: "", //mensaje wt
                      tiempo: "00:00:00",
                      mensajesWt: []
                 };
    this.camposLogisticos= [];
    this.plataformaCorreo= {};
    this.configuracion= {};
    this.valid={user: false, password: false, correo: false};
    this.acumuladores=[];
    this.correoPlantillas= [];
    this.correoPlantilla= [];
    this.plantillas= [];
    this.movimientoTipos = [];
    this.configuracionPlantillaConsolidado= { "idCorreoPlantilla": 0, "idMovimientoTipo": 0, "idCampoLogistico": 0 };
    this.mensajesWt= { "idCorreoPlantilla": 0, "mensaje": "", "tiempo": "00:00:00" };
  }

  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "correoPlantillas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "correoPlantillas"){
                if(returnVal[0].id){
                  this.correoPlantillas= returnVal;
                  this.setState({ correoPlantillas: returnVal });
                  this.plantillas= [];
                  this.plantillas.push({ value: 0, label: "Selecciona una plantilla ", indice: 0 })
                  returnVal.map((pl, index)=>{
                    this.plantillas.push({ value: pl.id, label: pl.nombre, indice: index });
                  })
                  this.setState({ plantillas: this.plantillas });
                }
              }
              if (stateVar === "movimientosTipo"){ this.movimientoTipos = [];
                returnVal.map((mt, index)=>this.movimientoTipos.push({ value: mt.id, label: mt.nombre, data: mt }) );
                this.setState({ movimientosTipo: this.movimientoTipos  });
                if(this.props.accion == 'nuevo'){this.setState({ idMovimientoTipo: { value: 0 , label: "Selecciona un Tipo de movimiento" }});}
              }
              if (stateVar === "configuracion"){                
                this.setState({ configuracionesPlantillaConsolidado: returnVal });
              }
              if (stateVar === "mensajes_wt"){                
                this.setState({ mensajesWt: returnVal });
              }
              if (stateVar === "camposLogisticos" && returnVal) {
                let calogs= [{ value: null, label:"Seleccione un campoLogistico"}];
                for(let item of returnVal){
                  calogs.push({ value: item.id, label: item.nombre, data: item });
                }
                this.camposLogisticos= calogs;
                this.setState({ idCampoLogistico: { value: 0, label: "Seleccione un tipo de movimiento"} });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "correoPlantillas"){
                if(returnVal.id){
                  this.correoPlantillas.push(returnVal);
                  this.setState({ correoPlantillas: this.correoPlantillas });
                  this.plantillas.push({ value: returnVal.id, label: returnVal.nombre, indice: this.correoPlantillas.length-1 });
                  this.setState({ plantillas: this.plantillas });
                  NotificationManager.info("Plantilla creada correctamente( "+returnVal.nombre+" )", "Nuevo");
                }
              }
              if( stateVar === "configuracion" ){
                NotificationManager.info("Guardado ..", "Nuevo");
                let confTemp = this.state.configuracionesPlantillaConsolidado;
                confTemp.push(returnVal);
                this.setState({ configuracionesPlantillaConsolidado: confTemp });
              }
              if( stateVar === "mensajes_wt" ){
                NotificationManager.info("Guardado ..", "Nuevo");
                let confTemp = this.state.mensajesWt;
                confTemp.push(returnVal);
                this.setState({ mensajesWt: confTemp });
              }
            }
            break;
          case "put":
            {
              if (stateVar === "correoPlantillas"){
                if(returnVal.id){
                  this.correoPlantillas.map((pl, index)=>{
                    if(pl.id == returnVal.id){
                      this.correoPlantillas[index]=returnVal;
                      this.setState({ correoPlantillas: this.correoPlantillas });
                      NotificationManager.info("Plantilla modificada correctamente( "+returnVal.nombre+" )", "Modificado");
                    }
                  })
                }
              }
              if (stateVar === "mensajes_wt"){
                if(returnVal.id){
                  let confTemp = this.state.mensajesWt;
                  confTemp.map((mwt, index)=>{
                    if(mwt.id == returnVal.id){
                      confTemp[index]=returnVal;
                      this.setState({ mensajesWt: confTemp });
                      NotificationManager.info(" mensaje modificado correctamente( "+returnVal.mensaje+" )", "Modificado");
                    }
                  })
                }
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "configuracion"){
                let confTemp = this.state.configuracionesPlantillaConsolidado;
                confTemp.map((item, index)=>{
                  if( item.idCorreoPlantilla+"/"+item.idMovimientoTipo+"/"+item.idCampoLogistico === id ){
                    if(returnVal){
                      confTemp.splice(index, 1);
                      NotificationManager.info("Borrado ..", "Nuevo");
                    }else{
                      NotificationManager.info("No se ha podido borrar ..", "Error");
                    }                    
                    this.setState({ configuracionesPlantillaConsolidado: confTemp });
                  }
                });              
              }
              if (stateVar === "mensajes_wt"){
                let confTemp = this.state.mensajesWt;
                confTemp.map((item, index)=>{
                  if( item.id === id ){
                    if(returnVal){
                      confTemp.splice(index, 1);
                      NotificationManager.info("Borrado ..", "Nuevo");
                    }else{
                      NotificationManager.info("No se ha podido borrar ..", "Error");
                    }                    
                    this.setState({ mensajesWt: confTemp });
                  }
                });              
              }
            }
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }
  componentDidMount=() =>{
    this.acumuladores= [];
    this.acumuladores.push({ value: 0, label: "Selecciona un acumulador ",indice: 0 })
    this.props.stor.acumuladores_object.map((acumulador, index)=>{
      this.acumuladores.push({ value: acumulador.id, label: acumulador.nombre, indice: index })
    })
    this.setState({ acumuladores: this.acumuladores });
    this.getfuntion("get", "", "", "camposLogisticos", "camposLogisticos");
    this.getfuntion("get", "", "", "correoPlantillas/plataforma/"+this.props.elementos.id,"correoPlantillas");
    this.getfuntion("get", "", "", "movimientosTipo/plataforma/"+this.props.elementos.id,"movimientosTipo");
  }
  changeAcumulador=(event) =>{
    this.setState({ getPlantilla: "" });
    this.setState({ acumulador: event });
    var found=false;
    this.state.plantillas.map((pl, index)=>{
      if( this.state.correoPlantillas[pl.indice].idAcumulador.id == event.value ){
        this.setState({ currentplantilla: pl });
        if(this.state.correoPlantillas[pl.indice]){
          this.correoPlantilla= this.state.correoPlantillas[pl.indice];
          this.setState({ plantilla: this.state.correoPlantillas[pl.indice].plantilla })
          found=true;
          this.setState({ cron: this.state.correoPlantillas[pl.indice].cron });
          this.configuracionPlantillaConsolidado.idCorreoPlantilla= this.state.correoPlantillas[pl.indice].id;
          this.mensajesWt.idCorreoPlantilla= this.state.correoPlantillas[pl.indice].id;
          this.getfuntion("get", "", "", "mensajes_wt/id_correo_plantilla/"+this.state.correoPlantillas[pl.indice].id, "mensajes_wt");
          this.getfuntion("get", "", "", "configuracionPlantillaConsolidado/correoPlantilla/"+this.state.correoPlantillas[pl.indice].id, "configuracion");
        }
      }
    });

    if(!found){
      this.setState({ plantilla: "" });
      this.setState({ currentplantilla:{ value: 0, label: "Selecciona una plantilla ", indice: 0 } });
      /*
      var bool= window.confirm("No existe un plantilla, Deseas crear una?");
      if(bool){
        alert("Se esta preparando una plantilla defauld");
      }else{
        alert("cancelo la solicitud");
      }*/
    }    
  }
  changePlantillas=(event) =>{
    this.setState({ getPlantilla: "" });
    this.setState({ currentplantilla: event });
    this.setState({ cron: this.state.correoPlantillas[event.indice].cron });
    this.acumuladores.map((acumulador, index)=>{
      if( acumulador.value ==  this.state.correoPlantillas[event.indice].idAcumulador.id){
        this.setState({ acumulador: acumulador });
      }
    });
    if(this.state.correoPlantillas[event.indice]){
      this.correoPlantilla= this.state.correoPlantillas[event.indice];
      this.setState({ plantilla: this.state.correoPlantillas[event.indice].plantilla });
      this.configuracionPlantillaConsolidado.idCorreoPlantilla= this.state.correoPlantillas[event.indice].id;
      this.mensajesWt.idCorreoPlantilla= this.state.correoPlantillas[event.indice].id;
    }
    this.getfuntion("get", "", "", "configuracionPlantillaConsolidado/correoPlantilla/"+this.state.correoPlantillas[event.indice].id, "configuracion");
    this.getfuntion("get", "", "", "mensajes_wt/id_correo_plantilla/"+this.state.correoPlantillas[event.indice].id, "mensajes_wt");
  }
  setPlantilla=(plan) =>{
    this.setState({ getPlantilla: plan });//plan;
  }
  changeTablaPosicion=(e)=>{
    this.correoPlantillas[this.state.currentplantilla.indice].vertical= e.target.checked;
    this.setState({ correoPlantillas: this.correoPlantillas });
    this.correoPlantilla.vertical= e.target.checked;
  }

  savePlantilla=() =>{
    this.correoPlantilla.plantilla= this.state.getPlantilla;
    this.getfuntion("put", this.correoPlantilla, "", "correoPlantillas", "correoPlantillas")
  }
  saveConfiguracionPlantillaConsolidado=() =>{
    this.getfuntion("post", this.configuracionPlantillaConsolidado, "", "configuracionPlantillaConsolidado", "configuracion");
  }
  borrarConfiguracionPlantillaConsolidado=(idcorrPlant, idmTipo, idCamLog) =>{
    this.getfuntion("delete", "", idcorrPlant+"/"+idmTipo+"/"+idCamLog, "configuracionPlantillaConsolidado", "configuracion");
  }
  changeCron=(e) =>{
    this.correoPlantillas[this.state.currentplantilla.indice].cron= e.target.value;
    this.setState({ correoPlantillas: this.correoPlantillas });
    this.correoPlantilla.cron= e.target.value;
    this.setState({ cron: e.target.value });
  }
  changeMovimientoTipo = (event) => {
    this.setState({ idMovimientoTipo: event });
    this.configuracionPlantillaConsolidado.idMovimientoTipo= event.value;
    let idMotipoCampoLog= 0;
    if( event.value === 1 || event.value === 3 ){ idMotipoCampoLog= 1 }
    if( event.value === 2 || event.value === 4 ){ idMotipoCampoLog= 2 }
    idMotipoCampoLog= this.props.elementos.plataformaTipo.id === 2? 3 : idMotipoCampoLog;
    let camposMapeados= "arribo_cliente_lleno, arribo_cliente_vacio, fecha_arribo, salida_cliente_vacio, salida_cliente_lleno, fecha_salida, arribo_patio_linea, arribo_terminal_vacio_2, arribo_terminal_cargado";
    let camposMapeadosTer= "fecha_arribo, fecha_salida";
    let camposMapeadosTemp= this.props.elementos.plataformaTipo.id === 2? camposMapeadosTer: camposMapeados;
    let calogs= [{ value: 0, label:"Seleccione un campoLogistico"}];
    for(let item of this.camposLogisticos){
      if(item.data){
        if( item.data.idMovimientoTipo === idMotipoCampoLog && camposMapeadosTemp.includes(item.data.nombreCampo) ){
          calogs.push(item);
        }
      }
    }
    this.setState({ camposLogisticos: calogs });
  }
  changeCampoLogistico=(e) =>{
    this.setState({ idCampoLogistico: e });
    this.configuracionPlantillaConsolidado.idCampoLogistico=  e.value;
  }
  //arroba amarilla
  changeMensaje=(e) =>{
    this.setState({ mensaje: e.target.value });
    this.mensajesWt.mensaje= e.target.value;
  }
  changeTiempo=(e) =>{;
    this.mensajesWt.tiempo= e.target.value;
    this.setState({ tiempo: e.target.value });
  }
  editarMensajeWt=(m) =>{
    this.setState({mensaje: m.mensaje });
    this.setState({tiempo: m.tiempo });
    this.mensajesWt= m;
  }
  cancelarMensajeWt=() =>{
    this.setState({mensaje: "${tiempo}" });
    this.setState({tiempo: "00:00:00" });
    this.mensajesWt= { "idCorreoPlantilla": 0, "mensaje": "", "tiempo": "00:00:00" };
  }
  saveMensajeWt=() =>{
    this.getfuntion(this.mensajesWt.id?"put":"post", this.mensajesWt, "", "mensajes_wt", "mensajes_wt");
  }
  borrarMensajeWt=(id) =>{
    this.getfuntion("delete", "", id, "mensajes_wt", "mensajes_wt");
  }
  //Fin arroba amarilla
  getMovimientoTipoLabel=(id)=>{
    for(let item of this.state.movimientosTipo){ if( item.value === id ){ return item.label; } }
    return "";
  }
  getCampoLogisticoLabel=(id)=>{
    for(let item of this.camposLogisticos){ if( item.value === id ){ return item.label; } }
    return "";    
  }
  validar=()=>{
    let invalido= false;
    this.state.configuracionesPlantillaConsolidado.map((conf, index)=>{
      if( this.state.idMovimientoTipo.value === conf.idMovimientoTipo || //this.state.idCampoLogistico.value === conf.idCampoLogistico ||
          this.state.idMovimientoTipo.value === 0 || this.state.idCampoLogistico.value === 0){
        invalido= true;
      }
    });
    return invalido;
  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  }
   render(){
    return (
      <div>
        {/**
          *   Configuracion de correo
          */
        }
        <Grid.Row>
          <Grid.Col sm={12} md={2}>
            <Form.Group label="Acumulador">
              <Select
                value={this.state.acumulador}
                onChange={this.changeAcumulador}
                options={this.state.acumuladores}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col sm={12} md={2}>
            <Form.Group label="Plantillas">
              <Select
                value={this.state.currentplantilla}
                onChange={this.changePlantillas}
                options={this.state.plantillas}
                //isDisabled={ !this.state.isConfig }
              />
            </Form.Group>
          </Grid.Col>
          { this.state.currentplantilla.value> 0 ?
            <Grid.Col sm={12} md={2}>
              <Form.Switch type="Checkbox" name="vertical" value="option1" checked={this.state.currentplantilla.value> 0?
                this.state.correoPlantillas[this.state.currentplantilla.indice].vertical: false }
                onChange={ this.changeTablaPosicion }  label={this.state.currentplantilla.value> 0?
                this.state.correoPlantillas[this.state.currentplantilla.indice].vertical? "Tabla vertical":"Tabla horizontal":"" } />
            </Grid.Col>
          :null}
          { this.state.currentplantilla.value> 0 ?
            <Grid.Col sm={12} md={2}>
              <Form.Group label="Resaltar campos vacios">
                <span>
                  { this.state.correoPlantillas[this.state.currentplantilla.indice].camposVaciosColor?
                      <i className="fa fa-square mt-6 mr-2" style={{fontSize:"36px", color: this.state.correoPlantillas[this.state.currentplantilla.indice].camposVaciosColor}}></i>
                    :  <i className="fa fa-square-o mt-6 mr-2" style={{fontSize:"36px"}}></i> 
                  }
                </span>
                <span>
                  <input
                    type="color"
                    name={"textoVacio"}
                    onChange={(e)=>{
                      this.correoPlantillas[this.state.currentplantilla.indice].camposVaciosColor= e.target.value;
                      this.setState({ correoPlantillas: this.correoPlantillas });
                      this.correoPlantilla.camposVaciosColor= e.target.value;
                    }}
                    value={this.state.correoPlantillas[this.state.currentplantilla.indice].camposVaciosColor}
                  />
                </span>
              </Form.Group>
            </Grid.Col>
          :null}
          { this.state.acumulador.value === 6 && this.state.currentplantilla.value> 0 ?//arroba amarilla
            <Grid.Col sm={12} md={2}>
              <Form.Group label="Establecer mensajes para Wating Time">
                <ModalLayout tamanio = {"60%"} title= "WT" name= {
                    <span className="badge"><Button pill size="sm" outline color="primary"><Icon name="edit"/></Button></span>
                  } hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                    formulario= {
                      <div>
                        <Grid.Row>
                          <Grid.Col sm={5} md={5}>
                            <Form.Group label={<Form.Label aside={(this.state.mensaje.length)+"/500"}>Utilizar la etiqueta $&#123;tiempo&#125;,ejeplo: Se le informa que su Unidad se ha excedido del tiempo libre de carga o descarga: $&#123;tiempo&#125; </Form.Label>}>
                              <Form.Textarea
                                value={ this.state.mensaje }
                                name="mensajewt0101"
                                placeholder="Contenido.."
                                rows={5}
                                onChange={ this.changeMensaje }
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col sm={5} md={5}>
                            <Form.Group label="Enviar notificación despues de arribo cliente">
                              <Form.MaskedInput
                                placeholder="HH:MM:SS"
                                value={this.state.tiempo}
                                onChange={ this.changeTiempo }
                                mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col sm={2} md={2}>
                            <span className="badge float-left">
                              <Button pill  size="sm" outline color="primary" onClick={ ()=> this.saveMensajeWt() } disabled={ this.state.mensaje.length<2 }>
                                <Icon name="loader"/>Guardar
                              </Button>
                            </span>
                            <span className="badge float-left">
                              <Button pill  size="sm" outline color="danger" onClick={ ()=> this.cancelarMensajeWt() } ><Icon name="x"/>Cancelar</Button>
                            </span>
                          </Grid.Col>
                        </Grid.Row>
                        <Table cards={false} striped={false} responsive={true} className="table-vcenter"
                          headerItems={ [{content: "mensaje"}, {content: "tiempo"}, {content: "Edit"}, {content: "Borrar"}]} style={{color:"black", "font-size": "80%"}} >
                          <Table.Header></Table.Header>
                          <Table.Body>
                            { this.state.mensajesWt.map((mwt, index)=>
                              <Table.Row>
                                <Table.Col> { mwt.mensaje } </Table.Col>
                                <Table.Col> { mwt.tiempo }</Table.Col>
                                <Table.Col>
                                  <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.editarMensajeWt(mwt) } > <span class="badge"><Icon link={true} name="edit" /></span> </Button>
                                </Table.Col>
                                <Table.Col>
                                  <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={() => this.borrarMensajeWt(mwt.id) } > <span class="badge"><Icon link={true} name="trash" /></span> </Button>
                                </Table.Col>
                              </Table.Row>)
                              }
                          </Table.Body>
                        </Table>
                      </div>
                    }
                />
              </Form.Group>
            </Grid.Col>
          :null}
          { this.state.acumulador.value === 3 && this.state.currentplantilla.value> 0 ?//arroba azul
            <Grid.Col sm={12} md={2}>
              <Form.Group label="Para detener envio de consolidado">
                <ModalLayout tamanio = {"60%"} title= "Campo logistico" name= {
                    <span className="badge"><Button pill size="sm" outline color="primary"><Icon name="edit"/></Button></span>
                  } hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                    formulario= {
                      <div>
                        <Grid.Row>
                          <Grid.Col sm={5} md={5}>
                            <Form.Group label="Tipo de movimiento">
                              <Select
                                value={this.state.idMovimientoTipo}
                                onChange={this.changeMovimientoTipo}
                                options={this.state.movimientosTipo}
                                //isDisabled={ !this.state.isConfig }
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col sm={5} md={5}>
                            <Form.Group label="Campo logistico">
                              <Select
                                value={this.state.idCampoLogistico}
                                onChange={this.changeCampoLogistico}
                                options={ this.state.camposLogisticos } isLoading={this.state.camposLogisticos.length == 0? true:false} 
                                //isDisabled={ !this.state.isConfig }
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col sm={2} md={2}>
                            <span className="badge float-left">
                              <Button pill  size="sm" outline color="primary" onClick={ ()=> this.saveConfiguracionPlantillaConsolidado() } disabled={ this.validar() }>
                                <Icon name="loader"/>Guardar
                              </Button>
                            </span>
                          </Grid.Col>
                          </Grid.Row>
                        <Table cards={false} striped={false} responsive={true} className="table-vcenter"
                          headerItems={ [{content: "Tipo movimiento"}, {content: "Campo logistico"}, {content: "Acción"}]} style={{color:"black", "font-size": "80%"}} >
                          <Table.Header></Table.Header>
                          <Table.Body>
                            { this.state.configuracionesPlantillaConsolidado.map((conf, index)=>
                              <Table.Row>
                                <Table.Col> { this.getMovimientoTipoLabel(conf.idMovimientoTipo) } </Table.Col>
                                <Table.Col> { this.getCampoLogisticoLabel(conf.idCampoLogistico) }</Table.Col>
                                <Table.Col>
                                  <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={() => this.borrarConfiguracionPlantillaConsolidado(conf.idCorreoPlantilla, conf.idMovimientoTipo, conf.idCampoLogistico) } > <span class="badge"><Icon link={true} name="trash" /></span> </Button>
                                </Table.Col>
                              </Table.Row>)
                              }
                          </Table.Body>
                        </Table>
                      </div>
                    }
                />
              </Form.Group>
            </Grid.Col>
          :null}
          { this.state.acumulador.value === 3 && this.state.currentplantilla.value> 0 ?
            <Grid.Col sm={12} md={2}>
              <Form.Group label="Para envio de consolidado establecer el tiempo">
                <Form.MaskedInput
                  placeholder="HH:MM:SS"
                  value={this.state.cron}
                  onChange={ this.changeCron }
                  mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
                />
              </Form.Group>
            </Grid.Col>
          :null}

          <Grid.Col sm={1} md={1}>
            {this.state.acumulador.value > 0 && this.state.currentplantilla.value == 0 && this.props.getCorreoPlataforma ?
              <ModalLayout tamanio = {"80%"} title= "Crear" name= {
                  <span className="badge float-right"><Button pill size="sm" outline color="primary"><Icon name="plus"/>Nueva</Button></span>
                } hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                  formulario= { <CorreoPlantilla
                                  accion="nuevo"
                                  idAcumulador={ this.props.stor.acumuladores_object[this.state.acumulador.indice] }
                                  idCorreoPlataforma= { this.props.getCorreoPlataforma }
                                  getfuntion={ this.getfuntion }
                                  stor= { this.props.stor }
                  /> } />
            : this.state.currentplantilla.value > 0 ?
              <ModalLayout tamanio = {"80%"} title= { this.state.currentplantilla.label } name= {
                  <span className="badge float-right"><Button pill size="sm" outline color="primary"><Icon name="edit"/></Button></span>
                } hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                  formulario= { <CorreoPlantilla
                                  accion="modificar"
                                  idAcumulador={ this.props.stor.acumuladores_object[this.state.acumulador.indice] }
                                  idCorreoPlataforma= { this.props.getCorreoPlataforma }
                                  getfuntion={ this.getfuntion }
                                  correoPlantilla={this.correoPlantilla}
                                  stor= { this.props.stor }
                                />
                              }
              />
            :null}
          </Grid.Col>
          <Grid.Col sm={1} md={1}>
            <span className="badge float-left"><Button pill  size="sm" outline color="primary" onClick={ ()=> this.savePlantilla() } disabled={this.state.getPlantilla !== "" && this.getPlantilla !== null ? false: true }><Icon name="loader"/>Guardar</Button></span>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} md={12}>
            <Generador texto={this.state.plantilla} setPlantilla={ this.setPlantilla } Plataforma={this.props.elementos } correoPlantillas={this.state.currentplantilla.value>0 ?this.state.correoPlantillas[this.state.currentplantilla.indice]: null }/>
          </Grid.Col>
        </Grid.Row>
      </div>
    )
  }
}

export default Plantillas
