import React, { Component } from "react";
import TabsLayout from "../../components/TabsLayout.react";
import {
  CuentaEspejo,
} from "./Formularios";

class CuentasEspejoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTap: 0,
      id: "",
      accion: this.props.accion, //'nuevo'// modificar,
      ready: false,
    };
    this.tabs = []; //this.props.elementos.nombre
    this.CuentasEspejo = {
      title: "Cuentas Espejo",
      icon: "layout",
      panel: (
        <CuentaEspejo
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          plataforma={this.props.plataforma}
          cerrarModal={this.props.cerrarModal}
          usuarioCliente= {this.props.usuarioCliente}
        />
      ),
      close: false,
    };
 
    //this.Correos    ={ title:"Correos",   icon:"mail",     panel:<CorreosClit accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
  }
  componentDidMount = () => {};

  componentWillMount = () => {

   
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.tabs = [
        this.CuentasEspejo
      ];

    } else {
      this.tabs = [this.CuentasEspejo];
    }
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };
  render() {
    return (
      <div>
        <TabsLayout
          tabs={this.tabs}
          selectedTab={this.state.currentTap}
          selectTab={this.selectedTab}
        />
      </div>
    );
  }
}

export default CuentasEspejoForm;
