import React, { Component } from "react";
import TabsLayout from "../../components/TabsLayout.react";
import {
  Cliente,
  Contactos,
  Etiquetas,
  ContactosCorredor,
  WatsAppGrupos,
  ContactosClienteSolicitante
} from "./Formularios";

class ClienteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTap: 0,
      id: "",
      accion: this.props.accion, //'nuevo'// modificar,
      ready: false,
    };
    this.tabs = []; //this.props.elementos.nombre
    this.Cliente = {
      title: "Clientes",
      icon: "layout",
      panel: (
        <Cliente
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          plataforma={this.props.plataforma}
          cerrarModal={this.props.cerrarModal}
          usuarioCliente= {this.props.usuarioCliente}
        />
      ),
      close: false,
    };
    this.Contactos = {
      title: "Contactos Final",
      icon: "layout",
      panel: (
        <Contactos
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          plataforma={this.props.plataforma}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.ContactosCorredor = {
      title: "Contactos por corredor",
      icon: "layout",
      panel: (
        <ContactosCorredor
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          plataforma={this.props.plataforma}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };

    this.ContactosSolicitante = {
      title: "Contactos Clientes Solicitantes",
      icon: "layout",
      panel: (
        <ContactosClienteSolicitante
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          plataforma={this.props.plataforma}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Etiquetas = {
      title: "Etiquetas",
      icon: "git-merge",
      panel: (
        <Etiquetas
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.WatsAppGrupos = {
      title: "WatsApp Grupos",
      icon: "layout",
      panel: (
        <WatsAppGrupos
          cliente={this.props.elementos}
          stor={this.props.stor}
          plataforma={this.props.plataforma}
        />
      ),
      close: false,
    };
    //this.Correos    ={ title:"Correos",   icon:"mail",     panel:<CorreosClit accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
  }
  componentDidMount = () => {};

  componentWillMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.tabs = [
        this.Cliente,
        this.Contactos,
        this.ContactosCorredor,
        this.ContactosSolicitante
      ];
      if( this.props.plataforma ){
        this.tabs.push(this.WatsAppGrupos);
      }
      this.tabs.push(this.Etiquetas);
    } else {
      this.tabs = [this.Cliente];
    }
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };
  render() {
    return (
      <div>
        <TabsLayout
          tabs={this.tabs}
          selectedTab={this.state.currentTap}
          selectTab={this.selectedTab}
        />
      </div>
    );
  }
}

export default ClienteForm;
