import React, { useEffect, useState } from "react";
import { Form, Grid } from "tabler-react";
import Crud_Catalogos from '../herramientas/Crud_Catalogos';
import Select from "react-select";
import { TablePageable } from "../Helpers";

//const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
const user = JSON.parse(sessionStorage.getItem("usuario"));

const UltimaActividadUsuarios = () => {
    //console.log(currentUser);

    const [idPlataforma, setIdPlataforma] = useState({ value: 0, label: "Selecciona una plataforma" });
    const [plataformas, setPlataformas ]= useState([]);    
    const [ultimaActividadUsuariosPorPlataforma, setUltimaActividadUsuariosPorPlataforma] = useState([]);

    useEffect(() =>{
      getfuntion("get", "", "", "plataformas/usuario/"+user.id, "plataformas");      
    },[]);

    useEffect(() => { 
      if(idPlataforma.value){
        getfuntion("get", "", "", "usuariosLog/listaUltimaActividadUsuariosPorPlataforma/" + idPlataforma.value, "ultimaActividadUsuariosPorPlataforma"); 
      }               
    }, [idPlataforma]);

    const changePlataformas = (event) => {
      //console.log(event)
      setIdPlataforma(event);   
    };

    
    const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {    
        return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
              case "get":             
                if(stateVar === "plataformas" && returnVal){
                  let plataformasTmp= [];
                  for(let p of returnVal)
                    plataformasTmp.push({value: p.id, label: p.nombre, data: p});
                  setPlataformas(plataformasTmp);
                  if(plataformasTmp.length === 1 )
                    changePlataformas(plataformasTmp[0]);
                }
                if(stateVar === "ultimaActividadUsuariosPorPlataforma" && returnVal) {
                  let ultimaActividadUsuariosPorPlataformaTemp = [];
                  returnVal.map((i, index) => {        
                    ultimaActividadUsuariosPorPlataformaTemp.push(i)
                  });
                  //console.log(returnVal);
                  setUltimaActividadUsuariosPorPlataforma(ultimaActividadUsuariosPorPlataformaTemp);          
                }
                break;            
              default:
                break;
            }
        }).catch(err => { console.log(err); });
    }

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
            <h4 className="modal-title"> Ultima Actividad de Usuarios </h4>
        </div>
        <div className="modal-body" style={{ width: "98%"}}>
            <div style={{ height: "100%"}}>
              {!idPlataforma.value ? (
                <Grid.Row >
                  <h7 className="alert alert-primary" style={{ color: 'blue' }}>Selecciona una plataforma para ver la ultima actividad de los usuarios</h7>
                </Grid.Row>
              ): null}            
            <Grid.Row>
                <Grid.Col md={12} xl={12}>
                    <Grid.Row>
                    <Grid.Col md={4} lg={4}>
                        <Form.Group label="Plataforma">
                          <Select
                            value = {idPlataforma}
                            onChange = {changePlataformas}
                            options = {plataformas}
                            isDisabled = {plataformas.length === 1}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
            {idPlataforma.value ? (
            <Grid.Row>
              <TablePageable
                titulo={""}
                lista={ ultimaActividadUsuariosPorPlataforma }
                columnas={
                  [
                    {columna: "id_usuario", label: "Id Usuario"},
                    {columna: "nombre", label: "Usuario"},
                    {columna: "plataforma", label: "Plataforma"},
                    {columna: "id_movimiento", label: "Movimiento"},
                    {columna: "bitacora", label: "Bitacora"},
                    {columna: "descripcion", label: "Descripcion"},
                    {columna: "fecha", label: "Fecha"},
                  ]
                }
                id= "id_usuario_log"            
                csvname= {"Ultima Actividad de Usuarios"}            
                paginacion={true}
                elementosPorPagina={50}
              />
            </Grid.Row>
            ): null}                
            </div>
        </div>
      </div>
    </>
  )
}

export default UltimaActividadUsuarios
