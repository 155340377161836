import React, {useState} from 'react';
const softphoneContext = React.createContext();

const SoftphoneProvider = ({children}) => {
	const [softphone, setSoftphone] = useState(
		sessionStorage.getItem("softphone") !== null
		? JSON.parse(sessionStorage.getItem("softphone"))
		: {}
	);
	const [movimiento, setMovimiento] = useState({data: {}, estatus: false});
	const [llamarClic, setLlamarClic] = useState(null);
	const [ultimoNumero, setUltimoNumero] = useState(
		sessionStorage.getItem("softphoneLastNumber") !== null
		? sessionStorage.getItem("softphoneLastNumber")
		: null);
  const [queueMembers, setQueueMembers] = useState(
    sessionStorage.getItem("queueMembers") !== null
    ? JSON.parse(sessionStorage.getItem("queueMembers"))
    : {});
  const [queueCalls, setQueueCalls] = useState(
    sessionStorage.getItem("queueCalls") !== null
    ? JSON.parse(sessionStorage.getItem("queueCalls"))
    : {});

	const getValues=()=>{
		return softphone;
	}
	const getMovimiento=()=>{
		return movimiento;
	}
	const getLlamarClic=()=>{
		return llamarClic;
	}
	const getUltimoNumero=()=>{
		return ultimoNumero;
	}
  const getQueueMembers=()=>{
    return queueMembers;
  }
  const getQueueCalls=()=>{
    return queueCalls;
  }
	return (
		<softphoneContext.Provider
			value={{
				getValues: getValues,
				setSoftphone: setSoftphone,
				getMovimiento: getMovimiento,
				setMovimiento: setMovimiento,
				getLlamarClic: getLlamarClic,
				setLlamarClic: setLlamarClic,
				getUltimoNumero: getUltimoNumero,
				setUltimoNumero: setUltimoNumero,
        getQueueMembers: getQueueMembers,
        setQueueMembers: setQueueMembers,
        getQueueCalls: getQueueCalls,
        setQueueCalls: setQueueCalls
			}}
		>
			{children}
		</softphoneContext.Provider>
	);
}

export {SoftphoneProvider, softphoneContext};
