import React, { useState, useEffect } from "react";
import { Button, Paper, TableRow, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Usuario } from "../../../../Catalogos/Usuarios/Formularios";



const Credenciales = (props) => {
    console.log(props)
console.log(props.Movimiento.variables)
  let data = props.Movimiento.variables != null ? JSON.parse(props.Movimiento.variables).cuenta_espejo : null;
  console.log(data.url)
  //Estatus, Plataformas, Corredores, Transportistas, Clientes, Pais

  const [cuentaEspejo,setCuentaEspejo] = useState(data)

  

 

  useEffect(() => {
    
console.log(cuentaEspejo)
        window.open(
            cuentaEspejo.url,
          'width=900,height=600,scrollbars=NO'
        );
    
  }, [cuentaEspejo]);


  const handleCopy = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        
    } catch (err) {
        console.error('Failed to copy text: ', err);
    }
  };

  return (

    cuentaEspejo.password ? 
    
<div class="quote-container">
  <i class="pin"></i>
  <blockquote class="note yellow">
  <br></br>
  <TableRow sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center"><Paper sx={{height: '50px'}} className="copy-box">
            
  <Box sx={{p: '20px'}}>
            <p>Usuario: </p>
            </Box>
            <Box sx={{p: '20px'}}>
              {cuentaEspejo.usuario}
              
            </Box>
            <Tooltip title="Copiar">
                <Button onClick={()=>handleCopy(cuentaEspejo.usuario)} sx={{minWidth: '20px'}}>
                    <ContentCopyIcon sx={{height: '20px', color: '#216C17'}}/>
                </Button>
            </Tooltip>
        </Paper>
        <br></br>
        </TableRow>
                      <TableRow sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center"><Paper sx={{height: '50px'}} className="copy-box">
                      <Box sx={{p: '20px'}}>
            Contraseña: 
            </Box>
            <Box sx={{p: '20px'}}>
           
            {cuentaEspejo.password}
            </Box>
            <Tooltip title="Copy to clipboard">
                <Button onClick={()=>handleCopy(cuentaEspejo.password)} sx={{minWidth: '20px'}}>
                    <ContentCopyIcon sx={{height: '20px', color: '#216C17'}}/>
                </Button>
            </Tooltip>
        </Paper></TableRow>
    
  </blockquote>
</div>
   : "Sin Credenciales" 
    
  );

}
export default Credenciales;