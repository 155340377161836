import React, { useEffect, useState } from 'react';
import Stomp from "stompjs";
import {NotificationManager} from 'react-notifications';


let url =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
const url1 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
const url2 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-2.mq.us-east-1.amazonaws.com:61619";
const user = "WEBSOCKET";
const pass = "w3bs0k3t2024";
let client = Stomp.client(url);
let postOrigin = false;

const MonitoreoMensajeria = ({
    topic,
    mtipo,
    gpsTipo,
    mensaje,
    elementosT,
    elementosN,
    storeFunction,
    updateT,
    update,
    openmodal,
    metodo,
    stor
}) => {

  const [mensajeEntrante, setMensajeEntrante] = useState(null);

  useEffect(() => {
    //console.log(topic);
      if(topic.length){
        console.log("conectar al websocket: ");
        conectar();
      }    
  }, [topic]);

  const conectar = () => {        
    client = Stomp.client(url);
    client.heartbeat = {
        incoming: 20000,
        outgoing: 20000,
    };
    client.debug = () => {};
    client.reconnect_delay = 5000;
    client.connect(
        user,
        pass,
        (frame) => {
            //console.log(topic);
          //Conect callback       
          if (topic && topic.length) {
            for (let t of topic) {
                //console.log(topic);
              client.subscribe(t, (message) => { 
                //let actualizar= udate;        
                if (message.body.includes("{")) {  
                  //actualizar(message.body); 
                  //console.log(message.body); 
                  setMensajeEntrante(JSON.parse(message.body));
                }              
              });
            }
          }
        },
        (err) => {               
          url = (url === url1) ? url2 : url1;
          //error useCallback
          console.log(err);
          if (typeof err === "string" && err.toUpperCase().includes("LOST CONNECTION")) {
            setTimeout(() => {
              conectar();
            }, 5000);
            //alert(err);
          }        
        }
    );
  }

  useEffect(() => {
    if(mensajeEntrante){
      udate(mensajeEntrante);
    }    
  }, [mensajeEntrante]);

  useEffect(() => {
    if(metodo !== "get"  && stor.updateStatusMensaje_Object && mensaje.data){
        sendMessage(mensaje.data.idPlataforma.id + mensaje.data.corredor.id ,mensaje)
      }
    }, [metodo, mensaje]);

  const udate = (mensaje) => {
    console.log(mensaje.vista )
    console.log(gpsTipo)
        
    if(mensaje.vista == "navieraMovimientosMasivo"){
        //console.log("Socket masivo")
        if(mtipo){
          if(mtipo === mensaje.data.idMovimientoTipo.id){
          }else{ return;}
        }
        let movimientos=  elementosN;
        if (mensaje.accion !== "post"){
          movimientos.map((elemento, index) =>{
            switch (mensaje.accion) {
              case "put":     if(elemento["id"] == mensaje.data.id){ movimientos[index]= mensaje.data } break;
              case "delete":  if(elemento["id"] == mensaje.data.id){ movimientos.splice(index, 1) }     break;
              default:  break;
            }
          });
        }
        if (mensaje.accion === "post" && postOrigin === false){ movimientos.push(mensaje.data); }else{ postOrigin= false; }
        stor.movimientos_naviera_update(movimientos);
        if( update !== "" ){ update(); }
    } 


    if(mensaje.vista == "navieraMovimientos"){     
    
        if(mtipo){

        
          if(
            mensaje.data && mensaje.data.idMovimientoTipo
             && mtipo === mensaje.data.idMovimientoTipo.id
          ){
          }else{
            console.log("mensaje error ---- >>");
            console.log(mensaje);
            return;
          }
        }
        NotificationManager.info(mensaje.mensaje,
          mensaje.accion === "put" ?  "Modificación":
          mensaje.accion === "post" ? "Nuevo":
          mensaje.accion === "delete" ? "Finalizar Movimiento":"Acción", 2500);
        let movimientos = elementosN;
        if (mensaje.accion !== "post"){
          movimientos.map((elemento, index) =>{
            switch (mensaje.accion) {
              case "put":     if(elemento["id"] == mensaje.data.id){ movimientos[index]= mensaje.data } break;
              case "delete":  if(elemento["id"] == mensaje.data.id){ movimientos.splice(index, 1) }     break;
              default:  break;
            }
          });
        }

        if (mensaje.accion === "post" && postOrigin === false){ 

          if(gpsTipo != null && mensaje.data.idOperacionEstatus.id == gpsTipo){
             movimientos.push(mensaje.data); 
          }

          if(gpsTipo == undefined || gpsTipo == null){
             movimientos.push(mensaje.data); 
          }
            
        
        }else{ postOrigin= false; }
            stor.movimientos_naviera_update(movimientos);
        if( update !== "" ){ update(); }
    }

    if(mensaje.vista == "terrestreMovimientos"){
        NotificationManager.info(mensaje.mensaje,
            mensaje.accion === "put" ?  "Modificación":
            mensaje.accion === "post" ? "Nuevo":
            mensaje.accion === "delete" ? "Finalizar Movimiento":"Acción",200);
        let movimientos=  elementosT;
        if (mensaje.accion !== "post"){
            if(movimientos){    
              movimientos.map((elemento, index) =>{
               switch (mensaje.accion) {
                 case "put":
                   if(elemento["id"] === mensaje.data.id){
                     movimientos[index]= mensaje.data;
                   }
                   break;
                 case "delete":
                   if(elemento["id"] == mensaje.data.id){
                     movimientos.splice(index, 1) }
                   break;
                 default:  break;
               }
             });
           }
        }
        if (mensaje.accion === "post" && postOrigin === false){ movimientos.push(mensaje.data); }else{ postOrigin= false; }
        if(movimientos){
            //stor.movimientos_terrestres_update([...movimientos]);
            if( updateT !== "" ){ updateT([...movimientos]); }
        }            
    }
  }

  const sendMessage = (topic, msg) => {   
    stor.updateStatusMensaje(false);  
    postOrigin= true;
    /*if(msg.vista == "navieraMovimientosMasivo"){           
        client.send(topic, {}, JSON.stringify(msg));
    }*/
    if(msg.vista == "navieraMovimientos"){           
        client.send(topic, {}, JSON.stringify(msg));
    }     
    if(msg.vista == "terrestreMovimientos"){           
        client.send(topic, {}, JSON.stringify(msg));
    }         
  };

  return (
    <>      
    </>
  )
}

export default MonitoreoMensajeria;
