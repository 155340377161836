import React, { useState, useEffect } from "react";
import { NotificationManager} from 'react-notifications';
import { Crud_Catalogos } from "../herramientas/Crud_Catalogos";
import { tokenExpTime } from "../herramientas/JWT";
const softphone = sessionStorage.getItem("softphone") !== null? JSON.parse(sessionStorage.getItem("softphone")): {};
var tokenStore="tok";
const LogOut =()=> {
  useEffect(() => {
    desloguear();
  }, []);

  const desloguear=async()=>{ 
    if( softphone && softphone.id
        && tokenExpTime(sessionStorage.getItem("tok"))
        && tokenExpTime(sessionStorage.getItem("tok")) > new Date().getTime()
      ){
      if(softphone.pausaEstatus){
        await Crud_Catalogos(
          "softphone/pausa?estatus=false" + "&motivo=" + softphone.pausaMotivo,
          "softphone", "post", "", {}, "", "softphone")
        .then((data) => {
          NotificationManager.success(data.message, "Telefono");
        }).catch((err) => console.log(err));
      }
      await Crud_Catalogos( "softphone/desloguear_en_grupo", "softphone", "post", "", {}, "", "softphone")
        .then((data) => {
          NotificationManager.success(data.message, "Telefono");
        }).catch((err) => console.log(err));
    }
    cleanSesionStorage();
    window.location.href = "/login"; 
  }

  const cleanSesionStorage=()=>{
    sessionStorage.clear();
    localStorage.removeItem('avisosEstatusNav');
  }

  return (
    <>
    </>
  );
}
export default LogOut;
      