import React, { useState } from "react";
import { useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { softphoneContext } from "../../context/softphoneContext";
import { TablePageable } from "../../Helpers";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import PbxMensageria from "../../socket/PbxMensageria";
import { timeToDateFill, timeToTimeFull } from "../../herramientas/DateFormat";
/*
const colas={
  6001: {
    "members":[
      {"paused":false,"location":"PJSIP/salvador","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/cesar.valencia","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/jorge.coutino","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/karen.afn","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":true,"location":"PJSIP/pamiro","state":"DEVICE_NOT_INUSE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/chema.afn","state":"DEVICE_NOT_INUSE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/david.garcia","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/daniela.afn1","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":1,"lastCall":1736838186},
      {"paused":false,"location":"PJSIP/guillermo.moreno","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/alanguba","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0}]
    ,"name":"6001",
    "event":"update-queue"
  }
};*/
let queueTemp= {};
let queueCallsTemp= {};
const PbxDash = () => {
  const softphone = useContext(softphoneContext);

  const [topics, setTopics]= useState([]);
  const [queue, setQueue]= useState(softphone.getValues().id? softphone.getValues().grupoExtension: null);
  const [queueMembers, setQueueMembers]= useState(softphone.getQueueMembers());
  const [queueCalls, setQueueCalls]= useState(softphone.getQueueCalls());


  const [acvs, setAcvs]= useState([]);

  useEffect(()=>{
    queueTemp= softphone.getQueueMembers();
    //getfuntion( "put",softphonLog,"","softphone_llamada_log/","softphone_log");
  },[]);

  useEffect(()=>{
    console.log(softphone.getQueueMembers());
    setQueueMembers({...softphone.getQueueMembers()});
    sessionStorage.setItem("queueMembers", JSON.stringify({...softphone.getQueueMembers()}));
  },[softphone.getQueueMembers()]);

  useEffect(()=>{
    console.log(softphone.getQueueCalls());
    setQueueCalls({...softphone.getQueueCalls()});
    sessionStorage.setItem("queueCalls", JSON.stringify({...softphone.getQueueCalls()}));
  },[softphone.getQueueCalls()]);

  useEffect(()=>{
    if(softphone.getValues().id){
      setTopics(["/topic/pbxSkyOne-"+softphone.getValues().grupoExtension]);
    }
  },[softphone.getValues().id]);
  const getfuntion = ( metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "" ) => {
    return Crud_Catalogos( catalogo, "usuario", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":
            if( stateVar === "softphone_log"){
            }
            break;
          case "post":
            if (stateVar === "softphone_log") {
            }
            break;
          default:
            break;
        }
    }).catch((err) => {
        console.log(err);
    });
  };
  const procesarMensaje = (msg) => {
    console.log("procesarMensaje", msg);
    if ( msg && msg.event) {
      switch (msg.event) {
        case "update-queue":
          if(!queueTemp[msg.name]){
            queueTemp[msg.name]= {};
          }
          queueTemp[msg.name]= msg;
          console.log("update-queue", queueTemp);
          softphone.setQueueMembers({...queueTemp});
          break;
        case "update-member":
          console.log("update-member", msg);
          /*
          {
                "name": "6001",
                "member": {
                    "paused": false,
                    "location": "PJSIP/pamiro",
                    "state": "DEVICE_UNAVAILABLE",
                    "membership": "static",
                    "callsTaken": 0,
                    "lastCall": 0
                },
                "event": "update-member"
            }
          */
          if(queueTemp[msg.name]){
            let membersTemp= queueTemp[msg.name].members;
            for(let i=0; i<membersTemp.length; i++){
              if(membersTemp[i].location === msg.member.location){
                membersTemp[i]= msg.member;
                queueTemp[msg.name].members= membersTemp;
              }
            }
            softphone.setQueueMembers({...queueTemp});
          }
          break;
        case "queueCall":
          console.log("queueCall", msg);
            /*
            {
              "event":"queueCall",
              "dialstatus":"RINGING",
              "date":"2025-01-16 18:05:24",
              "name":"6001",
              "member":"suriel",
              "callerId":"527621004239",
              "exten":"525541603232"
            }

            */
            if(!queueCallsTemp[msg.name]){
              queueCallsTemp[msg.name]= [];
            }
            //Insertamos al principio
            queueCallsTemp[msg.name].unshift(msg);
            //verificamos si el elemento ya existe con anteriorida y lo borramos

            if( queueCallsTemp[msg.name].length > 1){
              for(let i=1; i<queueCallsTemp[msg.name].length; i++){
                if(queueCallsTemp[msg.name][i].callerId === msg.callerId){
                  queueCallsTemp[msg.name].splice(i, 1);
                }
              }
            }
           console.log(queueCallsTemp);
            softphone.setQueueCalls({...queueCallsTemp});
            break;
        default:
          break;
      }
    }
  };
  return (
    <div>
      <PbxMensageria topics={topics} obtenerMensaje={procesarMensaje}/>
      <div>
        <div className="d-flex flex-row justify-content-around">
            <div>
              <div>
                <div className="row btn">
                  <div className="col">
                    <i className="fa fa-gear text-dark" tyle={{ fontSize: "36px"}}></i>
                  </div>
                </div>
                <div className="row">
                  <p>Queue {queueMembers[queue]? queueMembers[queue].name: queue}</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="row">
                  <div className="col">
                    <i className="fa fa-users fa-2x mb-2 text-primary"></i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <h2 className="card-text">{queueMembers[queue]?.members.length}</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <p>Total</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="row">
                  <div className="col"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      queueMembers[queue]?
                        queueMembers[queue].members.filter(m=> m.state.toUpperCase() === "DEVICE_NOT_INUSE" && !m.paused)
                      .map(m=> m.location).join("\n")
                      :""
                    }
                  >
                    <i className="fa fa-users fa-2x mb-2 text-success"></i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <h2 className="card-text">{queueMembers[queue]?.members.filter(m=> m.state.toUpperCase() === "DEVICE_NOT_INUSE" && !m.paused).length}</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <p>Disponibles </p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="row">
                  <div className="col"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      queueMembers[queue]?
                      queueMembers[queue].members.filter(m=> m.state.toUpperCase() === "DEVICE_UNAVAILABLE" && !m.paused)
                      .map(m=> m.location).join("\n")
                      :""
                    }
                  >
                    <i className="fa fa-users fa-2x mb-2 text-danger"></i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <h2 className="card-text">{queueMembers[queue]?.members.filter(m=> m.state.toUpperCase() === "DEVICE_UNAVAILABLE" && !m.paused).length}</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <p>No Disponibles </p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="row">
                  <div className="col"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      queueMembers[queue]?
                      queueMembers[queue].members.filter(m=> m.paused)
                      .map(m=> m.location).join("\n")
                      :""
                    }
                  >
                    <i className="fa fa-user-times fa-2x mb-2 text-warning"></i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <h2 className="card-text">{queueMembers[queue]?.members.filter(m=> m.paused).length}</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <p>En pausa </p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="row">
                  <div className="col"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      queueMembers[queue]?
                      queueMembers[queue].members.filter(m=>
                        (!["DEVICE_NOT_INUSE","DEVICE_UNAVAILABLE"].includes(m.state.toUpperCase())))
                      .map(m=> m.location).join("\n")
                      :""
                    }
                  >
                    <i className="fa fa-feed fa-2x mb-2 text-warning"></i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <h2 className="card-text">{
                        queueMembers[queue]?.members.filter(m=>
                          (!["DEVICE_NOT_INUSE","DEVICE_UNAVAILABLE"].includes(m.state.toUpperCase()))).length}
                        </h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <p>Ocupados </p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="row">
                  <div className="col"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      queueCalls[queue]?
                      queueCalls[queue].map(c=> c.callerId+"("+c.member+")"+timeToTimeFull(c.date)).join("\n")
                      :""
                    }
                  >
                    <i className="fa fa-list fa-2x mb-2 text-warning"></i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <ul>
                        { queueCalls[queue]?
                          queueCalls[queue].slice(0, 2).map((e, i) =>
                            <li key={i}>
                              <small>{e.callerId}
                                <small className="text-muted">{"("+e.member+")"+timeToTimeFull(e.date)}</small>{/**  +"["+e.dialstatus+"]" */}
                              </small>
                            </li>
                          ): null
                        }
                      </ul>
                      {/*}
                      <ul>
                        <li>
                          <small>
                            <h6 className="mt-0">
                              527621004239
                              <small class="text-muted">pamiro.sotova.acv</small>
                            </h6>
                          </small>
                        </li>
                        <li>
                          <small>
                            <h6 className="mt-0">
                              527621004239
                              <small class="text-muted">pamiro.sotova.acv</small>
                            </h6>
                          </small>
                        </li>
                        <li>
                          <small>
                            <h6 className="mt-0">
                              527621004239
                              <small class="text-muted">pamiro.sotova.acv</small>
                            </h6>
                          </small>
                        </li>
                      </ul>*/}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <p> </p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div hidden={true}>
        <h5>Agentes</h5>
        <TablePageable
          titulo={ <h5>ACV'S</h5> }
          lista= { acvs}
          columnas={
            [
              { columna: "location", label: "Extensión" },
              { columna: "state", label: "Estatus" },
              { columna: "paused=", label: "Pausado" },/*
              { columna: "carta_porte", label: "Carta Porte",
                //valor: (e)=> incidencias.filter(i => i.incidencia.id === e.id  ).length? true: false,
                form: (e)=>
                  <Button variant="primary"
                    onClick={ ()=> {
                      //alert("aqui se hara update, llenado información de mov en log");
                      let softphonLogTemp= {...softphonLog};
                      softphonLogTemp.idMovimiento= e.id_movimiento;
                      getfuntion(
                        "put",
                        softphonLogTemp,
                        "",
                        "softphone_llamada_log",
                        "softphone_log");
                      handleCloseModal();
                    }
                  }>
                    <i className="fa fa-check-square-o"></i>
                  </Button>
              },*/
            ]
          }
          id= "location"
          //selected={(e)=>{ setFormulario(e) }}
          //csvname= {"Movmientos relacionados"}
          //resaltarFuncion={(e)=> incidencias.filter(i => i.incidencia.id === e.id  ).length ? true: false}
          //resaltarColor={"rgba(58, 223, 149, 0.25)"}
          //paginacion={true}
          elementosPorPagina={15}
        />
      </div>

    </div>
  );
};

export default PbxDash;
