import React from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import addNotification from 'react-push-notification';

const LlamadaModal = ({
  showModal,
  handleCloseModal,
  llamante,
  handleRefuse,
  handleAnswer,
}) => {
  useEffect(() => {
    if (showModal) {
      document.getElementById("ringtoneWebPhone").play();
      addNotification({
        title: 'llamada entrante',
        subtitle: 'Subtitulo',
        message: "llamada entrante de " + llamante,
        theme: 'darkblue',
        native: true, // when using native, your OS will handle theming.
        duration: 15000,
        //onClick: ()=>alert("llamada")
      });
    }
  }, [showModal]);
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <audio id="ringtoneWebPhone" src="songs/ringtone.mp3" loop></audio>
      <Modal.Header>
        <Modal.Title>LLamada entrante</Modal.Title>
      </Modal.Header>
      <Modal.Body>LLamada entrante de {llamante}!</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleRefuse}>
          Colgar
        </Button>
        <Button variant="primary" onClick={handleAnswer}>
          Responder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LlamadaModal;